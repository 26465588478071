import { gql } from "@apollo/client";
import { useCachedQuery } from "../CachedQuery";

export function useInviterInfo(inviteId) {
    return useCachedQuery(
        "inviterInfo",
        gql`
            query inviterInfo($inviteId: String!) {
                inviterInfo(inviteId: $inviteId) {
                    profileId
                    eventId
                    circleId
                }
            }
        `,
        {inviteId: inviteId},
        (data) => data?.inviterInfo,
        5,
        true
    );
}


export function useActivityByInviteSource(sourceId, userId) {
    return useCachedQuery(
        "activityByInviteSource",
        gql`
            query activityByInviteSource($sourceId: String!, $userId: String!) {
                activityByInviteSource(sourceId: $sourceId, userId: $userId)
            }
        `,
        {sourceId: sourceId, userId: userId},
        (data) => data?.activityByInviteSource,
        30,
        true
    );
}