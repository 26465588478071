import React from "react";
import { Alert, Stack, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@material-ui/core/Link";
import { Chip } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { masterDataCircleOperatesIn } from "../../apiServices/Constants";
import { useDispatch } from "react-redux";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import theme from "../../styles/container/Theme";
import { FormattedMessage } from "react-intl";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
import { getValidUrl } from "../../apiServices/CommonMethods";
import {ReactComponent as Globe} from "../../svg/ui/internet.svg";
import {ReactComponent as Pin} from "../../svg/ui/location.svg";


export default function CircleInfo(props) {
  const dispatch = useDispatch();

  const [circle, setCircleInfo] = React.useState({
    name: "",
    shortDescription: "",
    aboutus: "",
    logo: "",
    label: "",
    circleTags: [],
    operatesIn: "",
    externalUrl: "",
  });
  React.useEffect(() => {
    if (typeof props.circleDetails !== "undefined") {
      setCircleInfo(props.circleDetails);
      dispatch(
        updateBrandColor(
          props.circleDetails.brandColor ?? theme.palette.primary.main
        )
      );
      if (props.circleDetails.operatesIn) {
        setOperatesIn(props.circleDetails.operatesIn);
      }
    }

  }, [props]);

  const setOperatesIn = (value) => {
    const operatesIn = masterDataCircleOperatesIn().filter((item) => {
      return item.value.toString() === value;
    });
    // console.log("operatesIn filter", masterDataCircleOperatesIn())
    if (operatesIn.length) {
      setCircleInfo((prevState) => ({
        ...prevState,
        operatesIn: operatesIn[0].key,
      }));
    }
  };
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Background color="white" border="bottomLeft" p={5} sx={{height: "fit-content !important"}} container item gap={ThemeConstants.custom.spacings.lg}>
        {circle.shortDescription || circle.externalUrl || circle.aboutus ? (
          <>
            {typeof error !== "undefined" ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {<FormattedMessage id={"somethingWentWrong"} />}
              </Alert>
            ) : null}

            {/* info */}
            {circle.aboutus && 
            <Grid container item gap={ThemeConstants.custom.spacings.sm} height="fit-content !important">
              <Text item xs={12} variant="h2" id="CircleInfo_aboutus" color="background" bold/>
              <Text item xs={12} variant="body1" color="background" sx={{whiteSpace: "pre-line"}}>
                {circle.aboutus}
              </Text>
            </Grid>}

            {/* Location */}
            {circle.location && 
            <Grid container item xs={12} sm={5} gap={ThemeConstants.custom.spacings.sm} height="fit-content !important">
              <Text item xs={12} variant="h2" id="Location" color="background" bold/>
              <Pin/> <Text mt={0.2} ml={0.5} variant="body1" color="background" sx={{whiteSpace: "pre-line"}}>{circle.location} </Text>
            </Grid>
            }
            
            {/* Tags */}
            <Grid container item gap={ThemeConstants.custom.spacings.sm} height="fit-content !important">
              <Text item xs={12} variant="h2" id="Tags" color="background" bold/>
              <Chip variant="filled" color="background" label={circle.operatesIn}/>
              {(circle.circleTags ? circle.circleTags : []).map((tags) => (
                <Chip
                  key={tags}
                  label={tags}
                  variant="filled" color="background"
                />
              ))}
            </Grid>

            {/* website */}
            {circle.externalUrl && <Grid container item gap={ThemeConstants.custom.spacings.xs}>
              <Text item xs={12} variant="h2" id="Website" color="background" bold/>
              {circle.externalUrl &&
                <Grid item xs={12}>
                  <Link
                    href={getValidUrl(circle.externalUrl)}
                    target={"_blank"}
                  >
                    <Globe width={ThemeConstants.custom.spacings.ml} style={{position: "relative", top: "5px", marginRight: ThemeConstants.custom.spacings.sm}} />{" "}
                    <Text variant="body1" display="inline" color="background" id={"CircleInfo_website_link"} />
                  </Link>
                </Grid>
              }
            </Grid>}
          </>
        ) : (
          <Grid container spacing={4}>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 200,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Text variant="body1" id="noDataAvailable" />
              </Stack>
            </Box>
          </Grid>
        )}
      </Background>
    </ThemeProvider>
  );
}
