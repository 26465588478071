import { GET_EVENT_INFO } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";

export function useGetEvent(id, userId) {
  return useCachedQuery(
    "event",
    GET_EVENT_INFO,
    { id: id, loggedInUser: userId },
    (data) => data?.eventById?.result,
    30,
    true
  );
}
  