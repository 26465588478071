import { Button, Grid, ThemeProvider } from "@mui/material";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import { FormattedMessage } from "react-intl";

export default function Modal({children, open, onClose, buttons}) {
    return(
        <ThemeProvider theme={Theme_WIP}>
            {open && 
            <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0, pointerEvents: "none !important"}}>
                <Background container p={5} justifyContent="center" color="rgba(0,0,0,0.5)" sx={{position: "fixed", inlet: 0, zIndex: "9000 !important"}}>
                <Background gap={7} container item color="white" justifyContent="center" border="bottomLeft" mt={"25vh"} p={8} px={{xs: 5, md: 16}} maxWidth="sm" height="fit-content">
                    {children}
                    <Grid container item xs={12} gap={ThemeConstants.custom.spacings.md}>
                    {buttons}
                    <Button onClick={onClose} sx={{textDecoration: "underline"}}><FormattedMessage id="Button_Text_OK"/></Button>
                    </Grid>
                </Background>
            </Background>
            </div>}
        </ThemeProvider>
    );
}