import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { FormattedMessage, useIntl } from "react-intl";
import Styles from "../../styles/component/EventTabs/EventVisitorsStyle";
import { Button, CircularProgress, Hidden, Icon, Stack, ThemeProvider } from "@mui/material";
import Accordian from "../../components/Accordian";
import { Waypoint } from "react-waypoint";
import EventRequestAutosearchPage from "../EventRequestAutosearchPage";
import { userRoles } from "../../apiServices/Constants";
import RequestCard from "../Cards/RequestCard";
import { useHistory } from "react-router-dom";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useListRequests } from "../../apiServices/Queries/Collabs/GetCollabRequests";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import { ClearCache } from "../../apiServices/CommonMethods";
import Modal from "../modals/Modal";
import Text from "../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function EventRequest(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const [requests, setRequests] = React.useState([]);
  const [eventdata, setEventData] = React.useState(props?.eventDetails);

  useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventData(props.eventDetails);
    }
  }, [props]);
  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: null,
    pageNumber: 1,
    locationPlaceID: null
  });
  const getVariable = () => ({
      userId: userId,
      pageNumber: input["pageNumber"],
      location: input["location"],
      industries: input["industries"],
      skills: input["skills"],
      maxDistance: input["maxDistance"],
      collaborationType: input["collaborationType"],
      businessTypes: input["businessType"],
      searchInput: input["searchInput"],
      pageSize: parseInt(10),
      circleId: null,
      requestId: null,
      eventId: eventdata.id,
      locationPlaceID: input["locationPlaceID"],
      loginProfileId: null,
      profileReqOnly: null
    });


  const {data: req, loading: loading, updateVariables, refetch: refetch} = useListRequests(getVariable());
  const reload = () => {
    globals.functions.loggedInUser.refetchRole(); 
    ClearCache("allCollabRequests");
    ClearCache("circle_feed", globals);
    setInput({...input, pageNumber: 1});
    setRequests([]);
    setTimeout(() => refetch({...getVariable(), pageNumber: 1}), 100);
  };

  useEffect(() => updateVariables(getVariable()), [input, eventdata]);
  useEffect(() => {
    if(!req || !req.length)
      return;
    if (input["pageNumber"] === 1)
      setRequests(req);
    else
      setRequests(prevState => [
        ...prevState,
        ...req,
      ]);
  }, [req]);

  const showFilter = () => {
    // setRequests([]);
    //setSelectedRequestId(null)
    setInput(prevState => ({
      ...prevState,
      pageNumber: 1,
    }));
    setIsShowFilter(!isShowFilter);
  };

  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };
  const filterAction = inputs => {
    if (userRole.role == userRoles.freemium && (eventdata.loginUserStatus !== "Manager" ||
      eventdata.loginUserStatus !== "Attending") && eventdata.circleId === null) {
      navigateToGetPremium();
    } else {
      // console.log("filterAction inputs", inputs);
      setRequests([]);
      setInput(inputs);
      if (inputs.collaborationType !== null && inputs.collaborationType.length === 11) {
        setInput(prevState => ({
          ...prevState,
          collaborationType: null,
        }));
      }
      setInput(prevState => ({
        ...prevState,
        searchInput: null,
      }));
      setIsShowFilter(!isShowFilter);
    }
  };
  const onSearch = (searchData) => { };
  const onFocus = () => {
    if (userRole.role == userRoles.freemium && (eventdata.loginUserStatus !== "Manager" ||
      eventdata.loginUserStatus !== "Attending") && eventdata.circleId === null) {
      navigateToGetPremium();
    }
  };
  const onUserSelection = (userName) => {
    setRequests([]);
    //setSelectedRequestId(null);
    setIsLoading(false);
    // console.log("userName", userName);
    //const name = userName.replace(" ", "", "g");
    setInput((prevState) => ({
      ...prevState,
      searchInput: userName,
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessType: null,
      pageNumber: 1,
    }));
  };

  // const refresh = () => {
  //   setInput((prevState) => ({
  //     ...prevState,
  //     pageNumber: parseInt(1),
  //   }));
  // };
  const onReset = () => {
    const variable = {
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessType: null,
      pageNumber: 1,
    };
    setRequests([]);
    setInput(variable);
  };
  const navigateToProfile = (request) => {
    if (userRole.role == userRoles.freemium && request.sharedCircles.length <= 0) {
      navigateToGetPremium();
    } else {
      history.push("/dashboard/profile/" + request.userId, {
        selectedUserId: request.userId,
      });
    }
  };
  return (
    <ThemeProvider theme={Theme_WIP}>
    <Modal open={modal} onClose={() => setModal(false)} buttons={<Button variant="contained" onClick={() => history.push("/dashboard/requests")}><FormattedMessage id="ShareRequest_"/></Button>}>
      <Text item xs={12} color="background" id="ExplainEventRequestsTitle" bold variant="h2"/>
      <Text item xs={12} color="background" id="ExplainEventRequests"/>
    </Modal>
    <Grid container gap={ThemeConstants.custom.spacings.md}>
      <Grid container spacing={2} className={classes.mt10} marginBottom={2}>
        <Grid item md={8} xs={9}>
          <EventRequestAutosearchPage
            eventDetails={eventdata}
            onSearch={onSearch}
            onFocus={onFocus}
            onUserSelection={onUserSelection}
            placeHolder={intl.formatMessage({
              id: "CircleMember_SearchBar_Text",
            })}
          />
        </Grid>
        <Grid item sm={1} xs={1}>
          <FilterAltOutlinedIcon
            className={classes.iconFilter}
            onClick={showFilter}
          />
        </Grid>
        {(eventdata.loginUserStatus == "Manager" || eventdata.loginUserStatus == "Attending" || userId == globals.state.admin.userId) 
        &&
        <Grid item xs>
          <Hidden mdDown>
            <Button variant="contained" onClick={() => setModal(true)}><FormattedMessage id="AddRequest"/></Button>
          </Hidden>
          <Hidden mdUp>
            <Icon color="primary" sx={{fontSize: 40, cursor: "pointer"}} onClick={() => setModal(true)}>
              add_circle
            </Icon>
          </Hidden>
        </Grid>}
        {(input.businessType != null && input.businessType.length) ||
          (input.collaborationType != null && input.collaborationType.length) ||
          (input.skills != null && input.skills.length) ||
          (input.industries != null && input.industries.length) ||
          input.location != null ||
          input.maxDistance != null ? (
          <Grid item sm={1} xs={2}>
            {/* <RestartAltIcon className={classes.iconReset} onClick={onReset} /> */}
            <Typography
              component="h5"
              variant="h5"
              className={classes.iconReset}
              onClick={onReset}
            >
              Resetten
            </Typography>
          </Grid>
        ) : null}
        {/* <Grid item sm={12} xs={12}>
          { data.allCollaborationRequest.errorCode ? (
            <Alert severity="error">
              { data.allCollaborationRequest.errorCode === -1 ? (
                error
              ) : null}
            </Alert>
          ) : null}
        </Grid> */}
      </Grid>
      {isShowFilter ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Accordian onSearch={filterAction} filterType={"collabRequest"} />
          </Grid>
        </Grid>
      ) : loading && !requests.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : requests.length > 0 ? (
        requests.map((requestsdata, index) => (
          <React.Fragment key={index}>
            <Grid container item>
              <RequestCard
                event={eventdata}
                userRole={userRole}
                fromProfile={true}
                onRefresh={reload}
                request={requestsdata}
                onUserSelection={navigateToProfile}
              />
            </Grid>
            {index === requests.length - 2 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  // console.log("index", index);
                  const newPage = input["pageNumber"] + 1;
                  setIsLoading(true);
                  // console.log("newPage", newPage);
                  setInput((prevState) => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </React.Fragment>
        ))
      ) : (
        <Grid item xs={12} sm={12}>
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
    </ThemeProvider>
  );
}
