import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { useHistory } from "react-router";
import { EVENT_NEWS_BY_USER } from "../../apiServices/Queries";
import { CircularProgress, Stack, Hidden, Icon } from "@mui/material";
import { Waypoint } from "react-waypoint";
import { FormattedMessage, useIntl } from "react-intl";
import EventNewsCard from "../../components/Cards/EventNewsCard";
import Styles from "../../styles/component/EventTabs/EventNewsStyle";
import CircleNewsAutosearchPage from "../CicleNewsAutosearchPage";
import { userRoles } from "../../apiServices/Constants";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(Styles);

export default function EventNews(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const [eventData, setEventData] = React.useState(props?.eventDetails);
  const [eventNews, setEventNews] = React.useState([]);
  const [selectedNewsId, setSelectedNewsId] = React.useState("");
  let [input, setInput] = React.useState({
    searchInput: "",
    pageNumber: 1,
  });
  useEffect(() => {
    if (props.selectedNewsId !== null) {
      setSelectedNewsId(props.selectedNewsId);
      // console.log("selectedNewsId", props.selectedNewsId);
    }
  }, []);
  const getVariable = () => {
    const variable = {
      id: eventData.id,
      userId: userId,
      loginProfileId: userId,
      pageNumber: input["pageNumber"],
      pageSize: parseInt(5),
      searchText: input["searchInput"],
      newsID: selectedNewsId ? selectedNewsId : null,
    };
    // console.log("Eventnews variable", variable);
    return variable;
  };


  const { loading: loading, data: newsData} = useCachedQuery(
    "event_news",
    EVENT_NEWS_BY_USER,
    getVariable(),
    (data) => data?.eventNewsByUser?.result,
    5,
    true
  );
  useEffect(() => {
    if (input["pageNumber"] === 1)
      setEventNews(newsData);
    else if (newsData.length > 0)
      setEventNews((prevState) => [...prevState, ...newsData]);
  }, [newsData]);

  useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventData(props.eventDetails);
    }
    // console.log("EventNews Event Data", eventData);
    // console.log("EventNews News ApI Data", eventNews);
  }, [input, props]);

  const refresh = () => {
    setEventNews([]);
    setInput((prevState) => ({
      ...prevState,
      pageNumber: parseInt(1),
    }));
    setSelectedNewsId(null);
    // refetch();
  };

  const onSearch = (searchtext) => {
    setEventNews([]);
    // console.log("SearchText", searchtext);
    setInput((prevState) => ({
      ...prevState,
      searchInput: searchtext,
      pageNumber: parseInt(1),
    }));
    setSelectedNewsId(null);
  };

  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };
  const onFocus = () => {
    // console.log("Circle News Circle id", eventData.circleId);
    if (userRole === userRoles.freemium && eventData.circleId !== null) {
      navigateToGetPremium();
    }
  };
  return (
    <Grid container gap={ThemeConstants.custom.spacings.md}>
      <Grid container spacing={4} className={classes.mb15}>
        <Grid item sm={6} xs={9}>
          <CircleNewsAutosearchPage
            onSearch={onSearch}
            onFocus={onFocus}
            placeHolder={intl.formatMessage({
              id: "EventPage_PlaceHolder_Text",
            })}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item sm={3} />
        </Hidden>

        {eventData.loginUserStatus === "Manager" || eventData.loginUserStatus === "SuperUser" ? (
          <Grid item sm={3} xs={2}>
            <Hidden mdUp>
              <Icon
                sx={{
                  color: theme.palette.secondary.main,
                  fontSize: 40,
                }}
                onClick={() => {
                  history.push("/AddEventNews", {
                    eventDetails: eventData,
                  });
                }}
              >
                add_circle
              </Icon>
            </Hidden>
            <Hidden mdDown>
              <ButtonDarkBlue
                onClick={() => {
                  history.push("/AddEventNews", {
                    eventDetails: eventData,
                  });
                }}
              >
                <FormattedMessage id={"Button_Add_new"} />
              </ButtonDarkBlue>
            </Hidden>
          </Grid>
        ) : null}
      </Grid>

      {loading && (!eventNews || !eventNews.length) ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : eventNews && eventNews.length > 0 ? (
        eventNews.map((newsdata, index) => (
          <Grid item container xs={12} key={index}>
            <EventNewsCard
              data={newsdata}
              onRefresh={refresh}
              eventData={eventData}
              key={index}
            />
            {index === eventNews.length - 2 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  // console.log("index", index);
                  // console.log("news.length", eventNews.length - 2);
                  const newPage = input["pageNumber"] + 1;
                  // console.log("newPage", newPage);
                  setInput((prevState) => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </Grid>
        ))
      ) : (
        <Paper elevation={3} className={classes.tabcontent}>
          <Grid container spacing={4}>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 200,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Typography
                  component="span"
                  variant="h3"
                  color={"textPrimary"}
                >
                  <FormattedMessage id={"noDataAvailable"} />
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
}
