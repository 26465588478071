import * as React from "react";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { ClearCache, GetUserId } from "../../apiServices/CommonMethods";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { CANCEL_EVENT_MANAGER } from "../../apiServices/mutations";
import FormContainer from "../Forms/FormContainer";

export default function CancelEvent(props) {
  const history = useHistory();
  const userId = GetUserId();
  const location = useLocation();
  const [eventInfo, setEventInfo] = React.useState(location?.state?.eventData);

  const [cancleEvent, { data: dataCancelEvent, loading: loadingCancelEvent }] =
    useMutation(CANCEL_EVENT_MANAGER, {
      onCompleted: (data) => {        
        ClearCache("event");
        ClearCache("allEvents");
      }
    });
  React.useEffect(() => {
    if (location.state && typeof location.state.eventData !== "undefined") {
      setEventInfo(location.state.eventData);
    }
    if (
      typeof dataCancelEvent !== "undefined" &&
      dataCancelEvent.eventClosed.errorCode === 0
    ) {
      history.push("/dashboard/events");
    }
  }, [location, dataCancelEvent]);

  const cancelEventAction = async () => {
    let variable = {
      eventId: eventInfo.id,
      loginUserId: userId,
    };
    // console.log(" cancelCircleByAdminAction variable", variable);
    cancleEvent({
      variables: variable,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        title        = {<FormattedMessage id="DeleteEvent"/>}
        description  = {<FormattedMessage id="CancelEvent_Description"/>}
        loading      = {loadingCancelEvent}
        onSave       = {cancelEventAction}
        disabled     = {loadingCancelEvent}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_Text_DeleteEvent"
        cancelId     = "Button_Text_GoBack"
      >
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
