import React from "react";
import { SHARE_CIRCLE_URL } from "../../Config";
import InviteShareButtons from "../Generic/InviteShareButtons";
import { useUUID } from "../../apiServices/Queries/Circles/GetUUID";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import InvitePeople from "../../containers/CirclesEventsSharedFunctionality/InvitePeople";

export default function CircleInvite(props) {
  const globals = useGlobalContext();
  const [circleData, setCircleData] = React.useState(props?.circleDetails);
  const [shareContent, setShareContent] = React.useState("");
  const {data: uuid} = useUUID(false, props.circleDetails.id);
  const shareUrl = SHARE_CIRCLE_URL + uuid;

  React.useEffect(() => {
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
      setShareContent("Ik ben lid van " + props.circleDetails.name + ". Kom je kennis maken? Check voor meer info: ")
    }
  }, [props]);

  return (
    <>
      <InviteShareButtons 
        name={circleData?.name}
        source="circle" 
        shareContent={shareContent} 
        shareUrl={shareUrl} 
        uuid={uuid} 
        authorized={circleData?.loggedInUserRole == "Moderator" || circleData?.loggedInUserRole == "SuperUser" || globals.state.loggedInUser.userId == globals.state.admin.userId}
      >
        {circleData  && <InvitePeople source="circle" details={circleData}/>}
      </InviteShareButtons>
    </>
  );
}
