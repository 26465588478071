import React from "react";
import InviteShareButtons from "../Generic/InviteShareButtons";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useUUID } from "../../apiServices/Queries/Circles/GetUUID";
import InvitePeople from "../../containers/CirclesEventsSharedFunctionality/InvitePeople";
import { SHARE_EVENT_URL } from "../../Config";

export default function EventInvite({eventData, circleData}) {
  const globals  = useGlobalContext();
  const shareContent = "Ik ga naar het event " + eventData?.title + ". Ben je er ook bij? Check voor meer info: ";
  const {data: uuid} = useUUID(true, eventData?.id);
  const shareUrl = SHARE_EVENT_URL + uuid;

  return (
    <>
      <InviteShareButtons
        name={eventData?.name}
        data={eventData}
        parent={circleData}
        source="event" 
        shareContent={shareContent} 
        shareUrl={shareUrl} 
        uuid={uuid} 
        authorized={eventData?.loginUserStatus == "Manager" || eventData?.loginUserStatus == "SuperUser" || globals.state.loggedInUser.userId == globals.state.admin.userId}
      >
        {eventData  && <InvitePeople source="event" details={eventData}/>}
      </InviteShareButtons>
    </>
  );
}
