import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import styles from "../../styles/container/PreRegisterPageStyle";
import { CssBaseline } from "@material-ui/core";
import { GlobalStyles, Divider, Button, ThemeProvider } from '@mui/material';
import { ButtonLightBlue } from "../../components/Buttons/ButtonLightBlue";
import { ButtonGrey } from "../../components/Buttons/ButtonGrey";
import { FormattedMessage } from "react-intl";
import VideoPlayer, { VimeoVideo } from "../../components/Media/VideoPlayer";
import { useAdminMetaData } from "../../apiServices/Queries/GetAdminMetaData";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import { useInviterInfo } from "../../apiServices/Queries/Invites/InviteInfo";
import QueryString from "query-string";
import { useGetCircle } from "../../apiServices/Queries/Circles/GetCircle";
import { useGetEvent } from "../../apiServices/Queries/Events/GetEvent";
import PageWithBanner from "../../components/Layout/PageWithBanner";
import CircleHeader from "../../components/Circles/CircleHeader";
import CircleInfo from "../../components/Circles/CircleInfo";
import Header from "../../components/Header";
import EventInfo from "../../components/EventTabs/EventInfo";
import EventHeader from "../../components/EventTabs/EventHeaders";

const useStyles = makeStyles(styles);

export default function PreRegisterPage() {
  const {data: systemInfo} = useAdminMetaData();
  const [progress, setProgress] = React.useState(1);
  const [watchedIntro, setWatchedIntro] = React.useState(false);
  const classes = useStyles();
  const params = QueryString.parse(window.location.search);
  const inviterInfo = useInviterInfo(params.inviteId);
  useEffect(() => {
    if(inviterInfo.data)
    localStorage.setItem("inviteInfo", JSON.stringify({...inviterInfo.data, inviteId: params.inviteId}));
  },[inviterInfo.data]);
  const circle      = useGetCircle(inviterInfo.data?.circleId, null);
  const event       = useGetEvent (inviterInfo.data?.eventId , null);
  useEffect(() => {
    if(inviterInfo.data?.eventId)
        event.updateVariables({id : inviterInfo.data?.eventId , loggedInUser: ""});
  },[inviterInfo.data?.eventId]);
  useEffect(() => {
    if(event.data?.circleId ?? inviterInfo.data?.circleId)
        circle .updateVariables({circleId: event.data?.circleId ?? inviterInfo.data?.circleId, loggedInUser: ""});
  },[inviterInfo.data?.circleId, event.data?.circleId]);

  const highlight = {hl: (...chunks) => <span style={{color: ThemeConstants.palette.lightPetrol.main}}>{chunks}</span>};
  const underline = {ul: (...chunks) => <span style={{textDecoration: "underline"}}>{chunks}</span>};

  const watched = () => setWatchedIntro(true);
  const completeFlow = () => {
    localStorage.setItem("seenVideo", true)
    window.location.reload();
  };

  const NeedHelp = () => <Grid container item xs={12} mt={ThemeConstants.custom.spacings.md}>
    <Text item xs={12} align="center" color="background" bold id="NeedHelp1"/>
    <Text item xs={12} align="center" color="background"      id="NeedHelp2" gridProps={{mt: ThemeConstants.custom.spacings.sm}} values={underline}/>
    <Text item xs={12} align="center" color="background" bold id="NeedHelp3" gridProps={{mt: ThemeConstants.custom.spacings.lg}}/>
    <Text item xs={12} align="center" color="background" ><b>master</b>match</Text>
  </Grid>

    const CirclePage = ({circleData}) => <PageWithBanner
    mt={"40px"}
    header={
        <CircleHeader
        image       = {circleData?.logo}
        parentTitle = {circleData?.parentCircleName}
        title       = {circleData?.name}
        button      = {<Button variant="contained" onClick={() => setProgress(2)}><FormattedMessage id="Register"/></Button>}
        bio         = {circleData?.shortDescription}
        />}
    >
    <Header/>
    {circleData && <CircleInfo circleDetails={{...circleData, externalUrl : null}} />}
    <NeedHelp/>
    </PageWithBanner>;

    const EventPage = ({eventData}) => <PageWithBanner
    mt={"40px"}
    header={
        <EventHeader
        image       = {eventData?.logo}
        parentTitle = {circle.data?.name}
        title       = {eventData?.title}
        button      = {<Button sx={{height: "fit-content"}} variant="contained" onClick={() => setProgress(2)}><FormattedMessage id="Register"/></Button>}
        bio         = {eventData?.quote}
        />}
    >
    <Header/>
    {eventData && <EventInfo eventDetails={{...eventData, externalUrl : null, registrationUrl : null, ticketingUrl: null, website: null}} />}
    <NeedHelp/>
    </PageWithBanner>;

    const page1 = () => (
    <Grid container spacing={3} flexDirection={"column"} alignItems="center">
        <Grid item>
            <Typography variant="h1" align="center" className={classes.h1}>
            <b>master</b>match
            </Typography>
        </Grid>
        <Grid item sx={{width: "100%"}}>
            <Divider className={classes.line}/>
        </Grid>
        <Grid item mt={4} alignSelf="center" justifyContent="center" alignItems="center">
            <Typography 
            style={{padding: "0px 30px"}}
            className={classes.h2}
            variant="h2" 
            align="center">
                <FormattedMessage id={"PreRegisterPage.Text3"} />
            </Typography>
        </Grid>
        <Grid item xs={12} mt="36px" pl={{xs: 4, md:0}}><img src="https://mastermatch.net/wp-content/uploads/2024/05/zakendoen_warme_relaties.png" style={{width: "100%", maxWidth:"497px"}}/></Grid>
        <Grid item className={classes.button} mt={"70px"}>
            <ButtonLightBlue
            onClick={setProgress} 
            value={2} >
                <span className={classes.buttonText}><FormattedMessage id={"PreRegisterPage_Lets_Start"} /></span>
            </ButtonLightBlue>
        </Grid>
    </Grid>
    );

    const page2 = () => (
    <Grid container gap={ThemeConstants.custom.spacings.lg} flexDirection={"column"} justifyContent="center" alignItems="center" >
        <Grid item >
            <Typography variant="h1" align="center" className={classes.h1}>
            <b>master</b>match
            </Typography>
        </Grid>
        <Grid item sx={{width: "100%"}} mt={-2}>
            <Divider className={classes.line}/>
        </Grid>
        {/* <Grid item>
            <Typography 
            className={classes.h2}
            variant="h2" 
            align="center">
                <FormattedMessage id={"PreRegisterPage_Video"} />
            </Typography>
        </Grid>
        {systemInfo?.preregisterVideoId && video()}
        <Grid item sx={{width: "300px"}}>
            {!watchedIntro?
            <ButtonGrey onClick={completeFlow} value={3} >
                <span className={classes.buttonText}><FormattedMessage id={"Skip"} /></span>
            </ButtonGrey>
            :
            <ButtonLightBlue onClick={completeFlow} value={3} >
                <span className={classes.buttonText}><FormattedMessage id={"PreRegisterPage_Continue"} /></span>
            </ButtonLightBlue>
            }
        </Grid> */}
        <Text item xs={12} bold variant="h1" color="primary" id="preonboarding1" values={highlight}/>
        <Text item xs={12} bold variant="h1" color="primary" id="preonboarding2" values={highlight}/>
        <Text item xs={12} bold variant="h1" color="primary" id="preonboarding3" values={highlight}/>
        <Text item xs={12} bold variant="h1" color="primary" id="preonboarding4" values={highlight}/>
        <Grid item mt={ThemeConstants.custom.spacings.ml}>
            <Button variant="contained" color="white" onClick={completeFlow} value={3} >
                <FormattedMessage id={"continue"} />
            </Button>
        </Grid>
    </Grid>
    );

    const video = () => (
    <Grid item sx={{aspectRatio:"16 / 11", width: "100%", height: "auto"}}>
        <VimeoVideo id={systemInfo?.preregisterVideoId} onComplete={watched}/>
    </Grid>
    )

  return (
    <ThemeProvider theme={Theme_WIP}>
    {event .data != null && progress == 1 ? <EventPage  eventData ={event .data}/> : 
     circle.data != null && progress == 1 ? <CirclePage circleData={circle.data}/> : <>
      <CssBaseline />
      <GlobalStyles
        styles={{
            body: { 
                backgroundColor: "#00394D !important",
                color: "white"
            }
        }}/>
      <Container component="main" maxWidth="sm" className={classes.container}>
        {progress==1? (page1()) : page2()}
      </Container>
    </>}
    </ThemeProvider>
  );
}