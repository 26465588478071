import React from "react";
import InvitePeople from "../CirclesEventsSharedFunctionality/InvitePeople";
import { CssBaseline, Grid, Hidden, ThemeProvider } from "@mui/material";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";

export default function InvitePeopleToEvent() {
  return(
    <ThemeProvider theme={Theme_WIP}>
    <CssBaseline/>
    <Navigationbar />
    <Grid container justifyContent="center">
      <Grid container maxWidth="sm" mt={16} item pb={10}>  
        <InvitePeople source="circle"/>
      </Grid>
    </Grid>
    <Hidden mdUp>
      <Footer />
    </Hidden>
  </ThemeProvider>);
}
