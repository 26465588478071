import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Link from "@material-ui/core/Link";
import { Button, Chip } from "@mui/material";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import LanguageIcon from "@mui/icons-material/Language";
import Styles from "../../styles/component/Circles/CircleInfoStyle";
import { FormattedMessage } from "react-intl";
import { get24Time, getLongDateWithYear, getValidUrl } from "../../apiServices/CommonMethods";
import { ThemeProvider } from "@mui/material";
import Background from "../../visualComponents/Background";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import {ReactComponent as Globe} from "../../svg/ui/internet.svg";
import {ReactComponent as Pin} from "../../svg/ui/location.svg";
import {ReactComponent as Warning} from "../../svg/ui/warning.svg";
import {ReactComponent as Calendar} from "../../svg/HeaderNavigationEtc/EventsIcon.svg";


const useStyles = makeStyles(Styles);

export default function EventInfo(props) {
  const classes = useStyles();
  const [event, setEventInfo] = React.useState(props?.eventDetails ?? {
    name: "",
    shortDescription: "",
    organizorNote: " ",
    description: "",
    logo: "",
    label: "",
    tagsDesc: [],
    isOrganizer: false,
    operatesInDesc: "",
    website: "",
    videoLink: "",
    ticketingUrl: "",

  });

  React.useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventInfo(props.eventDetails);
    }

  }, [props]);


  const handleButtonClick = () => {
    window.open(getValidUrl(event.videoLink), "_blank");
  }

  return (
    <ThemeProvider theme={Theme_WIP}>
      <Background color="white" p={5} border="bottomLeft" sx={{height: "fit-content"}} container item gap={ThemeConstants.custom.spacings.lg}>
          {!event.isOrganizer && <Grid container item xs={12} sm={12} gap={ThemeConstants.custom.spacings.sm} alignItems="center">
            <Warning/><Text item xs variant="body1" color="background" bold id={"Event_organizornote"} />
          </Grid>}

          {/* date & location */}
          {event.startDate && event.endDate && 
          <Grid container item xs={12} sm={5.5} gap={ThemeConstants.custom.spacings.sm}>
            <Text item xs={12} variant="h2" id="Date_time" color="background" bold/>
            <Calendar/> <Text mt={0.2} ml={0.5} variant="body1" color="background" sx={{whiteSpace: "pre-line"}}>{
                    getLongDateWithYear(event.startDate) +
                    ", " +
                    get24Time(event.startDate) +
                    " - " +
                    get24Time(event.endDate)
                    } </Text>
          </Grid>
          }
          {((!props.uuid && event.videoLink) || event.registrationUrl || event.location) && 
          <Grid container item xs={12} sm={5} gap={ThemeConstants.custom.spacings.sm}>
            <Text item xs={12} variant="h2" id="Location" color="background" bold/>
            {event.location && <><Pin/> <Text mt={0.2} ml={0.5} variant="body1" color="background" sx={{whiteSpace: "pre-line"}}>{event.location} </Text></>}
            {event.registrationUrl          && <Button variant="contained" color="primary" onClick={() => window.open(event.registrationUrl, "_blank")}><FormattedMessage id="toOnlineEvent"/></Button>}
            {!props.uuid && event.videoLink && <Button variant="contained" color="primary" onClick={handleButtonClick}><FormattedMessage id="Event_video_link"/></Button>}
          </Grid>
          }

          {/* websites */}
          {(event.website || event.ticketingUrl) && <Grid container item gap={ThemeConstants.custom.spacings.xs}>
            <Text item xs={12} variant="h2" id="Website" color="background" bold/>
            {event.website &&
              <Grid item xs={12}>
                <Link
                  href={getValidUrl(event.website)}
                  target={"_blank"}
                >
                  <Globe width={ThemeConstants.custom.spacings.ml} style={{position: "relative", top: "5px", marginRight: ThemeConstants.custom.spacings.sm}} />{" "}
                  <Text variant="body1" display="inline" color="background" id={"Event_Website_organisator"} />
                </Link>
              </Grid>
            }
            {event.ticketingUrl &&
              <Grid item xs={12}>
                <Link
                  href={getValidUrl(event.ticketingUrl)}
                  target={"_blank"}
                >
                  <Globe width={ThemeConstants.custom.spacings.ml} style={{position: "relative", top: "5px", marginRight: ThemeConstants.custom.spacings.sm}} />{" "}
                  <Text variant="body1" display="inline" color="background" id={"Event_register_tickets"} />
                </Link>
              </Grid>
            }
          </Grid>}

          {/* info */}
          {event.description && 
          <Grid container item gap={ThemeConstants.custom.spacings.sm}>
            <Text item xs={12} variant="h2" id="EventSettings_AboutTheEvent" color="background" bold/>
            <Text item xs={12} variant="body1" color="background" sx={{whiteSpace: "pre-line"}}> {event.description} </Text>
          </Grid>
          }

          {/* tags */}
          <Grid container item gap={ThemeConstants.custom.spacings.sm}>
            <Text item xs={12} variant="h2" id="Tags" color="background" bold/>
            <Grid item xs={12} sm={12}>
              {[1].map(number => (
                <Chip
                  key={event.operatesInDesc}
                  label={event.operatesInDesc}
                  variant="filled" color="background" sx={{margin: 1}}
                />
              ))}
              {(event.tagsDesc ? event.tagsDesc : []).map(tags => (
                <Chip
                  key={tags}
                  label={tags}
                  variant="filled" color="background" sx={{margin: 1}}
                />
              ))}
            </Grid>
          </Grid>
      </Background>
    </ThemeProvider>
  );
}
