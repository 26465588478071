import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { CircularProgress, Paper } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { GET_ALL_EVENTS_BYCIRCLEID } from "../../apiServices/Queries";
import { GetUserId } from "../../apiServices/CommonMethods";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import EventCard from "../Cards/EventCard";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { Waypoint } from "react-waypoint";
import { useDispatch } from "react-redux";
import CircleEventsStyle from "../../styles/component/Circles/CircleEventsStyle";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(CircleEventsStyle);

export default function CircleEvents({circleDetails}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const userId = GetUserId(false);
  const dispatch = useDispatch();
  const [events, setEvents] = React.useState([]);

  let [input, setInput] = React.useState({
    pageNumber: 1,
  });

  const getVariable = () => {
    const variable = {
      circleId: circleDetails.id,
      loginProfileId: userId,
      // hint: "",
      pageNumber: input["pageNumber"],
      pageSize: 50,
    };
    // console.log("variable12event", variable);
    return variable;
  };

  const { data, loading } = useCachedQuery(
    "events_byCircle",
    GET_ALL_EVENTS_BYCIRCLEID,
    getVariable(),
    (data) => data?.allEventsByCircleId?.result,
    60,
    true
  );
  useEffect(() => {
    if(!data) return;
    if (input["pageNumber"] === 1)
      setEvents(data);
    else if (data.length > 0)
      setEvents((prevState) => [
        ...prevState,
        ...data,
      ]);
  }, [data]);

  return (
    <Grid container gap={ThemeConstants.custom.spacings.md}>
      {circleDetails.loggedInUserRole === "Moderator" || circleDetails.loggedInUserRole === "SuperUser" ? (
        <Grid item xs={4} style={{ float: "right" }}>
          {/* <Hidden mdUp>
            <Icon
              sx={{
                color: circledata.brandSecondColor
                  ? brandColor
                  : theme.palette.secondary.main,
                fontSize: 40,
              }}
              onClick={() => {
                history.push("/eventNew", {
                  parentCircleData: circledata,
                });
              }}
            >
              add_circle
            </Icon>
          </Hidden>
          <Hidden smDown> */}
          <Button
            fullWidth
            variant="contained"
            onClick={() =>
              history.push("/eventNew", {
                parentCircleData: circleDetails,
              })
            }
            disableElevation
          >
            <FormattedMessage id={"NewEvent"} />
          </Button>
          {/* </Hidden> */}
        </Grid>
      ) : null}
      {loading && !events.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />
            <Typography
              component="h5"
              variant="h5"
              className={classes.title}
            ></Typography>
          </Stack>
        </Box>
      ) : events.length > 0 ? (
        events.map((item, index) => (
          <Grid item xs={12} sm={12}>
            <Paper
              elevation={3}
              className={classes.tabcontent}
              key={index}
              onClick={() => {
                // console.log("event selcted", item);
                // history.push("/dashboard/eventDetails", {
                //   eventDetails: item,
                //   circleDetails: circledata,
                // });
                history.push("/dashboard/eventDetails/?id=" + item.id, {
                  eventDetails: item,
                  circleDetails: circleDetails,
                });
                dispatch(
                  updateBrandColor(
                    item.brandColor ?? theme.palette.primary.main
                  )
                );
              }}
            >
              <EventCard eventData={item}></EventCard>
              {index === events.length - 2 && (
                <Waypoint
                  scrollableAncestor={window}
                  onEnter={() => {
                    // console.log("Waypointindex", index);
                    const newPage = input["pageNumber"] + 1;
                    // console.log("newPage", newPage);
                    setInput((prevState) => ({
                      ...prevState,
                      pageNumber: newPage,
                    }));
                  }}
                />
              )}
            </Paper>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} sm={12}>
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
