import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PreRegisterPage from "./Pages/PreRegisterPage";
import Register from "./Register";
import OnboardingPage from "./OnboardingPage";
import OnboardingStepper from "./OnboardingStepper";
import SigninOidc from "../components/IdentityServer/signin-oidc";
import SignoutOidc from "../components/IdentityServer/signout-oidc";
import Dashboard from "./Dashboard";
import LinkedInPopUp from "../components/LinkedIn/LinkedInPopup";
import Introduction from "./InroductionPage";
import RequestNew from "./RequestNew";
//import ChooseActivity from "./ChooseActivity";
import AddNote from "../components/Activities/AddNote";
import AddTask from "../components/Activities/AddTask";
import SendMessage from "../components/Activities/SendMessage";
import SendThankyou from "../components/Activities/SendThankyou";
import SuggestOneToOne from "../components/Activities/SuggestOneToOne";
import MatchRequest from "./MatchRequestNew";
import RespondToRequestPage from "./RespondToRequestPage";
import EventNew from "./Events/EventNew";
import AttendEvent from "./Events/AttendEvent";
import JoinEvent from "./Events/JoinEvent";
import AddNews from "./Events/AddNews";
import InvitePeopleToEvent from "./Events/InvitePeopleToEvent";
import InviteAllCirclesToEvent from "./Events/InviteAllCirclesToEvent";
import EventPublish from "./Events/EventPublish";
import ContactCircleManager from "./Circles/ContactCircleManager";
import { setContext } from "@apollo/client/link/context";
import AddCirclePost from "./Circles/AddCirclePost";
import AddNewCircle from "./Circles/AddNewCircle";
import AddNewSubCircle from "./Circles/AddNewSubCircle";
import CancelCircle from "./Circles/CancelConfirmationScreen";
import CircleDetails from "./Circles/CircleDetails";
import NewsRespond from "./Circles/NewsRespond";
import GetPremium from "../components/AccountSettingsTab/GetPremium";
import ChangePremium from "../components/AccountSettingsTab/ChangePremium";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import AddEventNews from "./Events/AddEventNews";
import EventNewsRespond from "./Events/EventNewsRespond";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { ClearItems, IsAuthenticated, isOnboardingProcessCompleted } from "../apiServices/CommonMethods";
import {
  ApolloGraphQlURI
} from "../Config";
import EventDetails from "./Events/EventDetails";
import CancelEvent from "../components/EventTabs/CancelEvent";
import InvitePeopleToCircle from "./Circles/InvitePeopleToCircle";
import AuthProvider from "../components/IdentityServer/authProvider";
import userManager, { renewToken, signinRedirect, signout, signoutRedirect } from "../components/IdentityServer/userService";
import jwtDecode from "jwt-decode";
import InvoiceDetails from "../components/AccountSettingsTab/InvoiceDetails";
import MolliePaymentStatus from "../components/AccountSettingsTab/MolliePaymentStatus";
import UserProvider from "../apiServices/UserProvider";
import OopsPage from "./Pages/OopsPage";
import StyleExpo from "./Pages/StyleExpo";
import ComponentExpo from "./Pages/ComponentExpo";
import ExtendTrial from "./Pages/ExtendTrial";
import MTLayout from "./Matchtime/MTLayout";
import EnvIndicator from "../components/DevelopmentTools/EnvIndicator";
import Profile from "./Profile";
import AppGlobalStateProvider from "../apiServices/Providers/AppGlobalStateProvider";

const auth = true; //redirection gets done by userprovider

//displays dashboard buttons and bars when authenticated, just exposes the component when not authenticated
const HalfExposedRoute = ({ component: Component, ...rest }) => {
  return (
    IsAuthenticated() ? 
    <Route {...rest}  component={Dashboard} /> : 
    <Route {...rest}  render={(props) => (<Component {...props} />)} />
  );
}

const MatchTimeRoute = ({ component: Component, ...rest }) => {
  return (
    IsAuthenticated()? 
    <Route {...rest}  render={(props) => (<Component {...props} />)} /> : 
    <Route {...rest}  render={(props) => (<Redirect to="/" />)} />
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const onboarded = isOnboardingProcessCompleted();
  return (
    <Route
      {...rest}
      render={(props) =>
        (
          onboarded ?
            <Component {...props} /> :
            <OopsPage/>
        )
      }
    />
  );
};

const PrivateRouteToMasters = ({ component: Component, ...rest }) => {
  const onboarded = isOnboardingProcessCompleted();
  return (
    <Route
      {...rest}
      render={(props) =>
        (
          onboarded ?
            <Redirect to="/dashboard/masters" /> :
            <OopsPage />
        )
      }
    />
  );
};

const OnBoardingRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        (
          <Component {...props} />
        )
      }
    />
  );
};

const InvalidRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        (
          <Redirect to="/dashboard/masters" />
        )
      }
    />
  );
};

const LoginRedirect = (props) => {
  useEffect(() => {
    ClearItems();
    signinRedirect();
  });
  return null;
};

const httpLink = createHttpLink({
  uri: ApolloGraphQlURI,
});

//todo read this from session user by using UserProvider. Not possible in current implementation
const authLink = setContext(async (_, { headers }) => {
  const accessToken = localStorage.getItem("accessToken");
  if (typeof accessToken !== "undefined" && accessToken !== null) {
    const { exp } = jwtDecode(accessToken)
    // Refresh the token a minute early to avoid latency issues
    const expirationTime = (exp * 1000)

    if (Date.now() >= expirationTime) {
      if (!localStorage.getItem("refreshToken")) {
        logoutAction()
      }
      await renewToken()
    }
  }
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const logoutAction = () => {
  signoutRedirect()
  ClearItems();
};

const client = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false, resultCaching: false }),
});

function App() {
  useEffect(
    () => setTimeout(window.location.reload, 2 * 1000 * 60 * 60),
  []);
  
  return (
    <ApolloProvider client={client}>
      <AuthProvider userManager={userManager} >
        <UserProvider 
        publicRegx={[
          "\\/dashboard\\/.*Details\\/\\?uuid=.*",
          "\\/dashboard\\/profile\\/\\?uuid=.*",
          "\\/ResetPassword.*"
        ]}
        publicPaths={[
          "/login",
          "/style-expo",
          "/component-expo",
          "/registration", 
          "/signin-linkedin", 
          "/signout-oidc", 
          "/signin-oidc", 
          "/ForgotPassword"]}>
          <EnvIndicator/>
          <AppGlobalStateProvider>
          <BrowserRouter basename="/">
            <Switch>
              <Route path="/registration"    component={localStorage.getItem("seenVideo") != null? Register : PreRegisterPage} />
              <Route path="/signin-linkedin" component={LinkedInPopUp} />
              <Route path="/signout-oidc"    component={SignoutOidc} />
              <Route path="/signin-oidc"     component={SigninOidc} />
              <Route path="/login"           component={LoginRedirect} />
              <Route path="/ForgotPassword"  component={ForgotPassword} />
              <Route path="/ResetPassword"   component={ResetPassword} />
              <Route path="/style-expo"      component={StyleExpo} />
              <Route path="/component-expo"  component={ComponentExpo} />
              <HalfExposedRoute path="/dashboard/circleDetails"   component={CircleDetails} />
              <HalfExposedRoute path="/dashboard/eventDetails"    component={EventDetails} />
              <HalfExposedRoute path="/dashboard/profile"         component={Profile} />
              <PrivateRouteToMasters path="/" exact={true} component={Dashboard} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <OnBoardingRoute path="/OnboardingPage" component={OnboardingPage} />
              <PrivateRoute path="/introduction" component={Introduction} />
              {/* <PrivateRoute path="/requestCreate" component={RequestNew} /> */}
              {/* <PrivateRoute path="/chooseActivity" component={ChooseActivity} /> */}
              <PrivateRoute path="/addTask" component={AddTask} />
              <PrivateRoute path="/addNote" component={AddNote} />
              <PrivateRoute path="/sendMessage" component={SendMessage} />
              <PrivateRoute path="/sendThankyou" component={SendThankyou} />
              <PrivateRoute path="/suggestOneToOne" component={SuggestOneToOne} />
              <PrivateRoute path="/matchRequest" component={MatchRequest} />
              <PrivateRoute path="/respondToRequest" component={RespondToRequestPage} />
              <PrivateRoute path="/eventNew" component={EventNew} />
              <PrivateRoute path="/attendEvent" component={AttendEvent} />
              <PrivateRoute path="/joinEvent" component={JoinEvent} />
              <PrivateRoute path="/addNews" component={AddNews} />
              <PrivateRoute path="/invitePeopleToEvent" component={InvitePeopleToEvent} />
              <PrivateRoute path="/inviteAllCirclesToEvent" component={InviteAllCirclesToEvent} />
              <PrivateRoute path="/eventPublish" component={EventPublish} />
              <PrivateRoute path="/contactCircleManager" component={ContactCircleManager} />
              <PrivateRoute path="/addCirclePost" component={AddCirclePost} />
              <PrivateRoute path="/addNewCircle" component={AddNewCircle} />
              <OnBoardingRoute path="/OnboardingStepper" component={OnboardingStepper} />
              <PrivateRoute path="/cancelCircle" component={CancelCircle} />
              <Route path="/circleDetails" component={CircleDetails} />
              <PrivateRoute path="/AddNewSubCircle" component={AddNewSubCircle} />
              <PrivateRoute path="/respondToNews" component={NewsRespond} />
              <Route path="/eventDetails" component={EventDetails} />
              <PrivateRoute path="/cancelEvent" component={CancelEvent} />
              {/* <PrivateRoute path="/GetPremium" component={GetPremium} /> */}
              <PrivateRoute path="/GetPremium" component={ExtendTrial} />
              <PrivateRoute path="/ChangePremium" component={ChangePremium} />
              <PrivateRoute path="/InvitePeopleToCircle" component={InvitePeopleToCircle} />
              <PrivateRoute path="/AddEventNews" component={AddEventNews} />
              <PrivateRoute
                path="/respondToEventNews"
                component={EventNewsRespond}
              />
              <PrivateRoute path="/molliePaymentStatus" component={MolliePaymentStatus} />
              <PrivateRoute path="/invoiceDetails" component={InvoiceDetails} />
              <MatchTimeRoute path="/matchtime*" component={MTLayout} />
              <InvalidRoute path="*" component={Dashboard} />
            </Switch>
          </BrowserRouter>
          </AppGlobalStateProvider>
        </UserProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}
export default App;
