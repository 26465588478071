import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import {
  CardMedia,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Avatar, Divider, Stack, TextField, ThemeProvider } from "@mui/material";
import Styles from "../../styles/component/Cards/RequestCardStyle";
import { ArrowForwardIos } from "@material-ui/icons";
import { useLocation, useHistory } from "react-router-dom";
import BusinessPartnerCardDetails from "../RequetsTabs/RequestCardDetails/BusinessPartnerCardDetails";
import ProjectPartnerCardDetails from "../RequetsTabs/RequestCardDetails/ProjectPartnerCardDetails";
import MentoringCardDetails from "../RequetsTabs/RequestCardDetails/MentoringCardDetails";
import InvestmentCardDetails from "../RequetsTabs/RequestCardDetails/InvestmentCardDetails";
import StrategicPartnerCardDetails from "../RequetsTabs/RequestCardDetails/StrategicPartnerCardDetails";
import CoFoundingCardDetails from "../RequetsTabs/RequestCardDetails/CoFoundingCardDetails";
import BrainStormingPartnerCardDetails from "../RequetsTabs/RequestCardDetails/BrainStormingPartnerCardDetails";
import CoworkingCardDetails from "../RequetsTabs/RequestCardDetails/CoworkingCardDetails";
import MastermindCardDetails from "../RequetsTabs/RequestCardDetails/MastermindCardDetails";
import MastersOfMastersCardDetails from "../RequetsTabs/RequestCardDetails/MastersOfMastersCardDetails";
import {
  ClearCache,
  getLongDate,
} from "../../apiServices/CommonMethods";
import {
  RequestCollaborationTypes,
  userRoles,
} from "../../apiServices/Constants";
import ExpertCardDetails from "../RequetsTabs/RequestCardDetails/ExpertCardDetails";
import { useMutation } from "@apollo/client";
import {
  DELETE_COLLAB_REQUEST,
  DONE_COLLAB_REQUEST,
  SAVE_MASTERMIND_REQUEST,
} from "../../apiServices/mutations";
import { FormattedMessage, useIntl } from "react-intl";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import theme from "../../styles/container/Theme";
import { useDispatch } from "react-redux";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import Text from "../../visualComponents/Text";
import UserPostedCard from "./Defaults/UserPostedCard";

const useStyles = makeStyles(Styles);

export default function RequestCard(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const userRole = props.userRole ?? {usedAllResponses: false};
  const dispatch = useDispatch();
  const [isLess, setIsLess] = React.useState(props.collapsed ?? true);
  const [request, SetRequest] = React.useState({});
  const [requestDetails, SetRequestDetails] = React.useState({});
  const [requestType, SetRequestType] = React.useState({});
  const [circleData, SetCircleData] = React.useState({});
  const [editing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const baseInput = {
    request                : {description: "", skills: []},
    offer                  : {description: "", skills: []}
  };
  const [input, setInput] = useState(baseInput);
  function updateInput(field, value) {
    var newValues = {};
    newValues[field] = value;
    setInput((prevState) => ({
        ...prevState,
        ...newValues,
    }));
  }


  const mutationOptions = {
    variables: {
        userId                  : globals.state.loggedInUser.userId,
        titleRequest            : title,
        request                 : input.request,
        offer                   : input.offer,
        selectedToWhomIds       : input.selectedToWhomIds ?? [],
        id                      : requestDetails.id,
        collaborationCategoryId : ""
    },
    onCompleted: (data) => {
        ClearCache("allCollabRequests", globals);
        ClearCache("circle_feed", globals);
        if (props.onRefresh) props.onRefresh();
        setEditing(false);
    }};
    const [UpdateRequest, { loading: loadingUpdate }] = useMutation(SAVE_MASTERMIND_REQUEST, mutationOptions);

  const [deleteRequest, { data, loading }] = useMutation(
    DELETE_COLLAB_REQUEST,
    {
      onCompleted: data => {
        ClearCache("allCollabRequests", globals);
        ClearCache("circle_feed", globals);

        if (
          typeof data !== "undefined" &&
          typeof props.onRefresh === "function"
        ) {
          // console.log("dataDELETE_COLLAB_REQUEST", data);
          props.onRefresh();
        }
      },
    }
  );
  const [doneRequest, { data: dataDoneRequest, loading: loadingDoneRequest }] =
    useMutation(DONE_COLLAB_REQUEST, {
      onCompleted: data => {
        if (
          typeof data !== "undefined" &&
          typeof props.onRefresh === "function"
        ) {
          // console.log("dataDoneRequest", data);
          props.onRefresh();
        }
      },
    });

  useEffect(() => {
    if (typeof props.request !== "undefined") {
      // console.log("props.request", props.request);
      SetRequest(props.request);
      getRequestDetails(props.request);
    }
    if (typeof props.circleData !== "undefined") {
      // console.log("props.circleData", props.circleData);
      SetCircleData(props.circleData);
    }
  }, [props, data, dataDoneRequest]);

  const getRequestDetails = requestDetails => {
    if (
      typeof requestDetails.businessPartnerCollaborationRequest !==
      "undefined" &&
      requestDetails.businessPartnerCollaborationRequest.length
    ) {
      SetRequestDetails(requestDetails.businessPartnerCollaborationRequest[0]);
      SetRequestType(RequestCollaborationTypes.businessPartners);
    } else if (
      typeof requestDetails.projectPartnerCollaborationRequest !==
      "undefined" &&
      requestDetails.projectPartnerCollaborationRequest.length
    ) {
      SetRequestDetails(requestDetails.projectPartnerCollaborationRequest[0]);
      SetRequestType(RequestCollaborationTypes.projectPartners);
    } else if (
      typeof requestDetails.mentoringCollaborationRequestPayload !==
      "undefined" &&
      requestDetails.mentoringCollaborationRequestPayload.length
    ) {
      SetRequestDetails(requestDetails.mentoringCollaborationRequestPayload[0]);
      SetRequestType(RequestCollaborationTypes.mentoring);
    } else if (
      typeof requestDetails.investmentCollaborationRequest !== "undefined" &&
      requestDetails.investmentCollaborationRequest.length
    ) {
      SetRequestDetails(requestDetails.investmentCollaborationRequest[0]);
      SetRequestType(RequestCollaborationTypes.investment);
    } else if (
      typeof requestDetails.strategicPartnerCollaborationRequest !==
      "undefined" &&
      requestDetails.strategicPartnerCollaborationRequest.length
    ) {
      SetRequestDetails(requestDetails.strategicPartnerCollaborationRequest[0]);
      SetRequestType(RequestCollaborationTypes.strategicPartners);
    } else if (
      typeof requestDetails.coFoundingPartnerCollaborationRequest !==
      "undefined" &&
      requestDetails.coFoundingPartnerCollaborationRequest.length
    ) {
      SetRequestDetails(
        requestDetails.coFoundingPartnerCollaborationRequest[0]
      );
      SetRequestType(RequestCollaborationTypes.coFounding);
    } else if (
      typeof requestDetails.brainstormPartnerRequest !== "undefined" &&
      requestDetails.brainstormPartnerRequest.length
    ) {
      SetRequestDetails(requestDetails.brainstormPartnerRequest[0]);
      SetRequestType(RequestCollaborationTypes.brainStormingPartners);
    } else if (
      typeof requestDetails.coworkingDateCollaborationRequest !== "undefined" &&
      requestDetails.coworkingDateCollaborationRequest.length
    ) {
      SetRequestDetails(requestDetails.coworkingDateCollaborationRequest[0]);
      SetRequestType(RequestCollaborationTypes.coworkingDates);
    } else if (
      typeof requestDetails.mastermindCollaborationRequest !== "undefined" &&
      requestDetails.mastermindCollaborationRequest.length
    ) {
      SetRequestDetails(requestDetails.mastermindCollaborationRequest[0]);
      SetRequestType(RequestCollaborationTypes.masterMinds);
    } else if (
      typeof requestDetails.mastersForMastersCollaborationRequest !==
      "undefined" &&
      requestDetails.mastersForMastersCollaborationRequest.length
    ) {
      SetRequestDetails(
        requestDetails.mastersForMastersCollaborationRequest[0]
      );
      SetRequestType(RequestCollaborationTypes.mastersOfMasters);
    } else if (
      typeof requestDetails.expertCollaborationRequest !== "undefined" &&
      requestDetails.expertCollaborationRequest.length
    ) {
      SetRequestDetails(requestDetails.expertCollaborationRequest[0]);
      SetRequestType(RequestCollaborationTypes.experts);
    }
  };
  const getRequestCardDetails = () => {
    if (
      typeof request.businessPartnerCollaborationRequest !== "undefined" &&
      request.businessPartnerCollaborationRequest.length
    ) {
      return (
        <BusinessPartnerCardDetails
          request={request.businessPartnerCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.projectPartnerCollaborationRequest !== "undefined" &&
      request.projectPartnerCollaborationRequest.length
    ) {
      return (
        <ProjectPartnerCardDetails
          request={request.projectPartnerCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.mentoringCollaborationRequestPayload !== "undefined" &&
      request.mentoringCollaborationRequestPayload.length
    ) {
      return (
        <MentoringCardDetails
          request={request.mentoringCollaborationRequestPayload[0]}
        />
      );
    } else if (
      typeof request.investmentCollaborationRequest !== "undefined" &&
      request.investmentCollaborationRequest.length
    ) {
      return (
        <InvestmentCardDetails
          request={request.investmentCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.strategicPartnerCollaborationRequest !== "undefined" &&
      request.strategicPartnerCollaborationRequest.length
    ) {
      return (
        <StrategicPartnerCardDetails
          request={request.strategicPartnerCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.coFoundingPartnerCollaborationRequest !== "undefined" &&
      request.coFoundingPartnerCollaborationRequest.length
    ) {
      return (
        <CoFoundingCardDetails
          request={request.coFoundingPartnerCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.brainstormPartnerRequest !== "undefined" &&
      request.brainstormPartnerRequest.length
    ) {
      return (
        <BrainStormingPartnerCardDetails
          request={request.brainstormPartnerRequest[0]}
        />
      );
    } else if (
      typeof request.coworkingDateCollaborationRequest !== "undefined" &&
      request.coworkingDateCollaborationRequest.length
    ) {
      return (
        <CoworkingCardDetails
          request={request.coworkingDateCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.mastermindCollaborationRequest !== "undefined" &&
      request.mastermindCollaborationRequest.length
    ) {
      return (
        <MastermindCardDetails
          onChange   = {updateInput}
          editing    = {editing}
          onComplete = {(newValue) => { setEditing(false); }} //todoSave
          collapsed  = {isLess}
          request    = {request.mastermindCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.mastersForMastersCollaborationRequest !== "undefined" &&
      request.mastersForMastersCollaborationRequest.length
    ) {
      return (
        <MastersOfMastersCardDetails
          request={request.mastersForMastersCollaborationRequest[0]}
        />
      );
    } else if (
      typeof request.expertCollaborationRequest !== "undefined" &&
      request.expertCollaborationRequest.length
    ) {
      return (
        <ExpertCardDetails request={request.expertCollaborationRequest[0]} />
      );
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const readLessAction = () => {
    setIsLess(true);
  };
  const navigateToIntroduction = () => {
    history.push("/introduction", {
      request: {
        ...requestDetails,
        givenName: request.givenName,
        familyName: request.familyName,
      },
    });
  };
  const navigateToMeeting = () => {
    if (props.event && (props.event.loginUserStatus == "Manager" || props.event.loginUserStatus == "Attending" || props.event.loginUserStatus == "SuperUser"))
      history.push("/suggestOneToOne", {
        request: {
          ...requestDetails,
          ...request
        },
        activity: {
          activity: {
            recipientUserProfileIds: request.userId,
            requesterUserProfileId:  userId
          },
          givenName:  request.givenName,
          familyName: request.familyName,
        },
      });
  };

  const navigateToRespondToRequest = () => {
    if (userRole.usedAllResponses && (circleData.loggedInUserRole !== "Moderator" &&
      circleData.loggedInUserRole !== "Member" &&
      circleData.loggedInUserRole !== "SuperUser")) {
        history.push("/getpremium");
    } else {
      history.push("/getpremium", {
        path: "/respondToRequest",
        referrer: window.location.pathname,
        request: { ...requestDetails, requestType: requestType },
        oldState: {...location?.state},
      });
    }
  };
  const readMore = () => {
    // console.log("request.userid", request);
    // console.log("userid", userId);

    setIsLess(false);
  };

  const navigateToActivity = () => {
    dispatch(updateBrandColor(theme.palette.primary.main));
    history.push("/dashboard/request", {id: requestDetails.id, request: props.request});
  };
  const deleteCollabRequest = () => {
    setAnchorEl(null);
    const variables = {
      collaborationType: requestType.value,
      loginProfileId: userId,
      id: requestDetails.id,
      userId: userId,
      circleId: circleData.id ? circleData.id : null,
    };
    // console.log("deleteCollabRequest variable", variables);
    deleteRequest({
      variables: variables,
    });
  };
  const doneCollabRequest = () => {
    setAnchorEl(null);
    const variables = {
      collaborationType: requestType.value,
      loginProfileId: userId,
      id: requestDetails.id,
      userId: userId,
    };
    // console.log("variable", variables);
    doneRequest({
      variables: variables,
    });
  };

  const checkValidity = (reqOrOffer) => {
    if(reqOrOffer == null)
        return({filled: false, complete: false, valid: true});
    else
        return({
            filled   :  (reqOrOffer.description?.length ?? 0) >    0 || (reqOrOffer.skills?.length ?? 0) >  0,
            complete :  (reqOrOffer.description?.length ?? 0) >    0 && (reqOrOffer.skills?.length ?? 0) >  0,
            valid    : ((reqOrOffer.description?.length ?? 0) >= 100 && (reqOrOffer.skills?.length ?? 0) >= 1)
                    || ((reqOrOffer.description?.length ?? 0) ==   0 && (reqOrOffer.skills?.length ?? 0) == 0)
        });
  };
  const validRequest = () => {
    var req   = checkValidity(input.request);
    var offer = checkValidity(input.offer);
    return(title && (req.valid && offer.valid))};

  return (
    <UserPostedCard
    title         = {editing? <TextField 
      disabled={loadingUpdate}
      inputProps={{ maxLength: 100 }}
      size="medium"
      sx={{div: {color: Theme_WIP.palette.primary.main, fontWeight: 800}, "svg, label, path, .MuiInputLabel-root, span": {pointerEvents: "none !important"}, "fieldset":{borderColor: "transparent"}, borderBottom: "solid 1px "+ Theme_WIP.palette.lighterPetrol.main}}
      value={title}
      onChange={(e) => setTitle(e.target.value)}/> : requestDetails.titleRequest}
    
    id        = {requestDetails.userId}
    username      = {request.givenName + " " + request.familyName}
    pfp           = {request.profilePictureUrl}
    label         = {(requestType.key != RequestCollaborationTypes.masterMinds.key || request?.mastermindCollaborationRequest[0]?.categoryTitle) ? (requestType.key == RequestCollaborationTypes.masterMinds.key ? request.mastermindCollaborationRequest[0].categoryTitle : requestType.key) : null}
    location      = {request?.baseLocation}
    role          = {request?.companyRole}
    company       = {request?.companyName}
    circles       = {request?.sharedCircles}
    info          =
      {requestDetails.userId !== userId ? (
        <span>
          {
            (request.noOfSharedMatches
              ? request.noOfSharedMatches
              : "0") +
            " " +
            intl.formatMessage({ id: "shared_matches" })}{" "}
        </span>
      ) : null}
    date          = {undefined}
    menuItems = 
      {props.fromProfile &&
      (requestDetails.userId === userId 
        || (typeof props.circleData !== "undefined" && props.circleData.loggedInUserRole === "Moderator")
      ) &&
        [
          <MenuItem onClick={navigateToActivity}>
            <FormattedMessage id={"allResponses"} />
          </MenuItem>,
          requestDetails.userId === userId && <MenuItem onClick={() => {setAnchorEl(null); setEditing(true); setTitle(requestDetails.titleRequest);}}>
            <FormattedMessage id={"edit"} />
          </MenuItem>,
          <MenuItem onClick={deleteCollabRequest}>
            <FormattedMessage id={"delete"} />
          </MenuItem>,
          requestDetails.userId === userId && (
            <MenuItem onClick={doneCollabRequest}>
              <FormattedMessage id={"MarkAsClosed"} />
            </MenuItem>
          )
        ]}
    respond =
      {!editing && requestDetails.userId !== userId && navigateToRespondToRequest}
    introduce =
      {!editing && requestDetails.userId !== userId && navigateToIntroduction}
    meeting =
      {!editing && props.event && requestDetails.userId !== userId && navigateToMeeting}
    onExpand = {setIsLess}
    >
      {/* request details */}
        {getRequestCardDetails()}
      
          
        {!editing ? null
        :
        <Grid container item xs={12} sm={12} style={{marginTop: 30}} gap={4}>
          <Button variant="contained" color="primary"    onClick={UpdateRequest}              disabled={loadingUpdate || !validRequest()} ><FormattedMessage id="Button_Text_Save"/></Button>
          <Button variant="contained" color="background" onClick={() => {setEditing(false);}} disabled={loadingUpdate}                    ><FormattedMessage id="go_Back"         /></Button>
        </Grid>
        }
    </UserPostedCard>
  );
}
