import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from '@mui/material/Grid';
import Container from "@material-ui/core/Container";
import { styled } from "@mui/material/styles";
import OnboardingStepper1 from "../components/Onboarding/OnboardingStepper1";
import PremiumFeatures from "../components/Onboarding/PremiumFeatures";
import LearnMasterMatch from "../components/Onboarding/LearnMasterMatch";
import MeetCollaborations from "../components/Onboarding/MeetCollaborations";
import { useHistory } from "react-router";
import { CssBaseline } from "@material-ui/core";
import Header from "../components/Header";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";
import Styles from "../styles/container/OnboardingStepperStyle";

const steps = ["1", "2", "3", "4"];
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#72B6CD",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#72B6CD",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: "dashed",
    borderColor: "#72B6CD",
    borderWidth: 3,
    borderRadius: 1,
    width: "27px",
    marginLeft: "-12px",
  },
}));
const useStyles = makeStyles(Styles);

export default function OnboardingStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [onboardingTab, setOnboardingTab] = React.useState(false);
  const [premiumTab, setPremiumTab] = React.useState(false);
  const [learnTab, setLearnTab] = React.useState(false);
  const [collabTab, setCollabTab] = React.useState(false);
  const history = useHistory();
  useEffect(() => {
    showTab()
  }, []);
  const showTab = (tab) => {
    if (localStorage.getItem("onBoardProgress") == null || localStorage.getItem("onBoardProgress") == 0) {
      setOnboardingTab(false);
      setPremiumTab(false);
      setLearnTab(true);
    } else if (localStorage.getItem("onBoardProgress") == 1) {
      setOnboardingTab(true);
      setPremiumTab(false);
      setLearnTab(false);
    } else if (localStorage.getItem("onBoardProgress") == 2) {
      setOnboardingTab(false);
      setPremiumTab(true);
      setLearnTab(false);
    } else if (localStorage.getItem("onBoardProgress") == 3) {
      setOnboardingTab(false);
      setPremiumTab(false);
      setLearnTab(false);
      setCollabTab(true);
    } else {
      history.push("/dashboard/masters")
    }
  };

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#72B6CD" : "white",
    border: "1px solid",
    borderColor: "#72B6CD",
    color: "#72B6CD",
    width: 50,
    height: 50,
    display: "flex",
    fontSize: 25,
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "white",
      border: "1px solid",
      borderColor: "#72B6CD",
      color: "#72B6CD",
      fontSize: 25,
      fontWeight: "bold",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#72B6CD",
      border: "1px solid",
      borderColor: "#72B6CD",
      color: "white",
      fontSize: 25,
      fontWeight: "bold",
    }),
  }));
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <Grid container item px={{xs: 5, md: 15}} py={10} xs={12} mb={3} justifyContent={"center"}>
        {onboardingTab ? <OnboardingStepper1 showTab={showTab} /> : null}
        {premiumTab ? (
          <PremiumFeatures showTab={showTab} />
        ) : null}
        {learnTab ? <LearnMasterMatch showTab={showTab} /> : null}
        {collabTab ? <MeetCollaborations showTab={showTab} /> : null}
      </Grid>
    </React.Fragment>
  );
}
