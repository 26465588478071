import * as React from "react";
import { Autocomplete, Chip, Grid, MenuItem, Select, TextField, TextareaAutosize, ThemeProvider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import ActivityUserSearch from "../ActivityUserSearch";
import DatePickerComponent from "../DatePicker/DatePicker";
import TimePickerPage from "../DatePicker/TimePickerPage";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import MembersMultipleUserSearch from "../Autocomplete/MembersMultipleUserSearch";

const MyInput = ({type, disabled, onChange, value, label, placeholder, options, min, max, inputProps, id, inputMode, color}) => {
    const sx = {
        "svg, path": {
            pointerEvents: "none !important",
            fill: ThemeConstants.palette.primary.main
        },
        fieldset: {
            border: "none"
        },
        label: {
            color: ThemeConstants.palette[color]?.contrastText ?? color
        },
        ".MuiFormControl-root, .MuiInputBase-root": {
            borderRadius: "5px",
            opacity: disabled? 0.65 : undefined,
            background: ThemeConstants.palette[inputProps?.color ?? color]?.main ?? color,
            color: ThemeConstants.palette[color]?.contrastText ?? color,
        },
        "input, .MuiSelect-select, textarea": {
            borderRadius: "5px",
            opacity: disabled? 0.65 : undefined,
            background: ThemeConstants.palette[inputProps?.color ?? color]?.main ?? color,
            color: ThemeConstants.palette[color]?.contrastText ?? color,
        }
    };
    if(type == "text")
        return <TextField sx={sx} placeholder={placeholder} fullWidth disabled={disabled} variant="outlined" inputMode={inputMode} id={id} value={value} inputProps={{...inputProps, maxLength: max}} onChange={onChange} label={label}/>;
    if(type == "textarea")
        return <TextareaAutosize style={{...sx["input, .MuiSelect-select, textarea"], minWidth: "100%", border: "none", padding: ThemeConstants.custom.spacings.md}} minRows={5} placeholder={placeholder} fullWidth disabled={disabled} variant="outlined" inputMode={inputMode} id={id} value={value} inputProps={{...inputProps, maxLength: max}} onChange={onChange} label={label}/>;
    if(type == "select")
        return <Select sx={sx} placeholder={placeholder} fullWidth id={id} disabled={disabled} onChange={onChange} inputMode={inputMode} inputProps={{...inputProps, style: {pointerEvents: "none"}}}>
            {options ? options.map(option =>
                <MenuItem value={option.value}><Text color={color}>{option.key}</Text></MenuItem>    
            ) : []}
        </Select>
    if (type == "contactsearch")
        return <ActivityUserSearch {...inputProps} inputProps={{...inputProps, id: id}} sx={sx} onChange={onChange} placeholder={placeholder}/>
    if (type == "date")
        return <DatePickerComponent {...inputProps} disabled={disabled} minDate={min} onChange={onChange} sx={sx} maxDate={max}/>
    if (type == "time" || type == "datetime")
        return <TimePickerPage type={type == "datetime" ? "datetime-local" : "time"} disabled={disabled} sx={sx} fullWidth onChange={onChange} value={value} minDate={min} maxDate={max}/>
    if (type == "toggle")
        return <ToggleButtonGroup fullWidth disabled={disabled} value={value} size="large" exclusive onChange={onChange}
        sx={{
            border: "2px solid" + (ThemeConstants.palette[color]?.main ?? color),
            button: {
                border    : "none",
                background: ThemeConstants.palette[color]?.main ?? color
            },
            ".Mui-selected": {
                backgroundColor: ThemeConstants.palette.white.main + " !important"
            }
        }}
        >
            {options ? options.map(option =>
                <ToggleButton value={option.value}><Text color={color}>{option.key}</Text></ToggleButton>    
            ) : []}
        </ToggleButtonGroup>
    if (type == "location")
        return <LocationAutoComplete id={id} fullWidth sx={sx} disabled={disabled} onChange={onChange} value={value} placeHolder={placeholder} />
    if (type == "circlemember")
        return <MembersMultipleUserSearch id={id} sx={sx} fullWidth disabled={disabled} circleDetails={options} onSearch={onChange} placeHolder={placeholder}/>
    if (type == "multipleText")
        return <MultipleTexts
        disabled={disabled} onChange={onChange} value={value} options={options} id={id} sx={sx}/>
    if (type == "users")
        return <Users
        disabled={disabled} onChange={onChange} value={value} options={options} id={id} sx={sx}/>
    return (<></>);
}

export default function InputSection({type, placeholder, disabled, onChange, value, title, description, hint, label, options, min, max, inputProps, props, color, id, inputMode, textColor}) {
    const color_     = color     ?? "background";
    const textColor_ = textColor ?? color_;

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container item xs={12} gap={ThemeConstants.custom.spacings.xs} {...props}>
        {title       && <Text item xs={12} variant="body1" bold color={textColor_}>{title}</Text>}
        {description && <Text item xs={12} variant="body1"      color={textColor_}>{description}</Text>}
        {type && <MyInput
            type={type} placeholder={placeholder} color={color_} inputMode={inputMode} disabled={disabled} onChange={onChange} value={value} label={label} options={options} min={min} max={max} inputProps={inputProps} id={id}
        />}
        {hint        && <Text item xs={12} variant="body2" bold color={textColor_}>{hint}</Text>}
    </Grid>
    </ThemeProvider>
  );
}

const MultipleTexts = ({disabled, onChange, value, label, placeholder, options, min, max, inputProps, id, inputMode, color, sx}) => {
    const [input, setInput] = React.useState("");

    return <Autocomplete disableClearable fullWidth multiple freeSolo id={id}
    disabled   = {disabled}
    onChange   = {onChange}
    options    = {options ?? []}
    value      = {value}
    renderTags = {(value, getTagProps) =>
        value.map((option, index) => (
        <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            size="small"
        />
        ))
    }
    renderInput={(params) => (
        <TextField
        {...params}
        onChange   = {(event) => setInput(event.target.value)}
        value      = {input}
        sx         = {sx}
        label      = {label}
        variant    = "outlined"
        InputProps = {{
            ...params.InputProps,
            type: "input",
        }}
        />
    )}
    />
}

const Users = ({disabled, onChange, value, label, placeholder, options, min, max, inputProps, id, inputMode, color, sx}) => {
    const [input, setInput] = React.useState([]);
    const onChange_ = (event, newvalue) => {
        newvalue = newvalue.map(optStringToId);
        if(onChange)
            onChange(event, newvalue);
    };
    const optFromOptString = (str) => {
        var opt = options.filter((o) => o.userId + o.givenName + " " + o.familyName == str);
        return opt.length? opt[0] : undefined;
    };
    const optStringToName = (str) => {
        var opt = optFromOptString(str);
        return opt ? opt.givenName + " " + opt.familyName : str;
    };
    const optStringToId = (str) => {
        var opt = optFromOptString(str);
        return opt ? opt.userId : str;
    };


    return <Autocomplete disableClearable fullWidth multiple freeSolo id={id}
    disabled   = {disabled}
    onChange   = {onChange_}
    options    = {
        options ? options.map((option) => 
                option.userId + option.givenName + " " + option.familyName
        ) : []
    }
    renderOption={(p, o, s) => <li {...p}>{o.substring(36)}</li>}
    value      = {value}
    renderTags = {(value, getTagProps) =>
        value.map((option, index) => (
        <Chip
            sx={{"svg": {pointerEvents: "all !important"}}}
            variant="outlined"
            label={optStringToName(option)}
            {...getTagProps({ index })}
            size="small"
        />
        ))
    }
    renderInput={(params) => (
        <TextField
        {...params}
        onChange   = {(event) => setInput(event.target.value)}
        value      = {input}
        sx         = {sx}
        label      = {label}
        variant    = "outlined"
        InputProps = {{
            ...params.InputProps,
            type: "input",
        }}
        />
    )}
    />
}