import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SelfInviteForm from "../../components/Invites/SelfInviteForm";
import EventInfo from "../../components/EventTabs/EventInfo";
import EventNews from "../../components/EventTabs/EventNews";
import EventVisitors from "../../components/EventTabs/EventVisitors";
import EventRequest from "../../components/EventTabs/EventRequest";
import EventInvite from "../../components/EventTabs/EventInvite";
import EventSettings from "../../components/EventTabs/EventSettings";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import styles from "../../styles/container/Event/EventDetailsStyle";
import { GET_EVENT_BY_UUID, GET_EVENT_INFO } from "../../apiServices/Queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import QueryString from "query-string";
import { Alert, CardMedia, Grid, Hidden, IconButton, Stack, ThemeProvider } from "@mui/material";
import { ClearCache, getLongDateWithYear } from "../../apiServices/CommonMethods";
import { get24Time } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import TabPanel from "../../components/ProfileTabs/TabPanel";
import { LEAVE_FROM_EVENT } from "../../apiServices/mutations";
import SuccessAlertDialog from "../../components/Alert/SuccessAlertDialog";
import { userRoles } from "../../apiServices/Constants";
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import Navigationbar from "../../components/Navigationbar";
import PageWithBanner from "../../components/Layout/PageWithBanner";
import { useGetCircle } from "../../apiServices/Queries/Circles/GetCircle";
import Label from "../../visualComponents/Label";
import {ReactComponent as More} from "../../svg/ui/more.svg";

const useStyles = makeStyles(styles);
export default function EventDetails(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const intl = useIntl();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [eventData, setEventData] = React.useState({});
  const [selectedEventId, setSelectedEventId] = React.useState("");
  const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(true);
  const [isLeave, setIsLeave] = React.useState(false);
  const [circleData, setCircleData] = React.useState({});
  const [selectedNewsId, setSelectedNewsId] = React.useState("");

  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const openPopover = Boolean(anchorElPopover);
  // const id = openPopover ? 'simple-popover' : undefined;
  const [usingUUID, setUsingUUID] = React.useState(false);
  const {data: circle, updateVariables} = useGetCircle(eventData?.circleId, userId);
  const [circleName, setCircleName] = useState(circle?.name);
  useEffect(() => eventData?.circleId && userId && updateVariables({circleId: eventData?.circleId, loggedInUser: userId}), [eventData?.circleId]);
  useEffect(() => circle?.name && setCircleName(circle.name), [circle]);

  const [leaveEvent, { data, loading }] = useMutation(LEAVE_FROM_EVENT, {
    onCompleted: (data) => {
      ClearCache("allEvents");
      if (
        typeof data !== "undefined" &&
        typeof data.leaveFromEvent !== "undefined" &&
        data.leaveFromEvent.errorCode === 0
      ) {
        history.push("/dashboard/events");
      } else if (
        typeof data !== "undefined" &&
        typeof data.leaveFromEvent !== "undefined" &&
        data.leaveFromEvent.errorCode === -1
      ) {
        setIsErrorAlertOpen(true);
      }
      setIsLeave(false);
    },
  });

  const [fetchEventInfo, { loading: loadingEventInfo, data: dataEventInfo }] =
    useLazyQuery(usingUUID? GET_EVENT_BY_UUID : GET_EVENT_INFO, {
      variables: usingUUID? { uuid: selectedEventId } : { id: selectedEventId, loginProfileId: userId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (typeof data !== "undefined") {
          if(usingUUID && data.eventByUUID.errorCode == 0)
            setEventData(data.eventByUUID.result);
          else if(!usingUUID && data.eventById.errorCode == 0)
          setEventData(data.eventById.result);
        }
      },
    });
  useEffect(() => {
    const params = QueryString.parse(window.location.search);
    //get the event details from query - id from the query parameter
    if (params.id) {
      setSelectedEventId(params.id);
      fetchEventInfo();
    }
    // get the event circle details from query - id from the saved state
    if (
      location.state &&
      typeof location.state.selectedEventId !== "undefined"
    ) {
      // console.log("selectedEventId", location.state.selectedEventId);
      setSelectedEventId(location.state.selectedEventId);
      if (typeof dataEventInfo === "undefined") {
        fetchEventInfo();
      }
    }
    //get the event details from query - uuid from the query parameter
    if (params.uuid) {
      setUsingUUID(true);
      setSelectedEventId(params.uuid); //we can reuse this state because the usingUUID state is used in fetchEventInfo to select the proper query
      fetchEventInfo();
    }

    if (location.state && typeof location.state.eventDetails !== "undefined") {
      setEventData(location.state.eventDetails);
    }

    if (location.state && typeof location.state.selectedTab !== "undefined") {
      setValue(location.state.selectedTab);
    }

    if (location.state && typeof location.state.circleDetails !== "undefined") {
      setCircleData(location.state.circleDetails);
      // console.log("Circle data :", location.state.circleDetails);
    }
    if (
      location.state &&
      typeof location.state.selectedNewsID !== "undefined"
    ) {
      setSelectedNewsId(location.state.selectedNewsID);
      // console.log("selectedNewsId", location.state.selectedNewsID);
    }

    if (!params.uuid && !params.id && (typeof location.state === "undefined" || (location.state && typeof location.state.eventDetails === "undefined" && typeof location.state.selectedEventId === "undefined"))) {
      history.push("/dashboard/events");
    }
  }, [location]);

  const handleChange = (newValue) => {
    setValue(newValue);
    setSelectedNewsId(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const allowedToAttend = () => {
    return userId != null
    && eventData.participantScopeDesc !== "Invite only event" 
    && eventData.participantScopeDesc !== "Besloten event" 
    && eventData.loginUserStatus !== "Manager";
  }
  const notAttendAction = () => {
    setAnchorEl(null);
    const variable = {
      id: eventData.id,
      loginProfileId: userId,
      profileId: userId,
    };
    // console.log("notAttendActionvar", variable);
    if (!isLeave) {
      setIsLeave(true);
      leaveEvent({
        variables: variable,
      });
    }
  };
  const goBack = () => {
    history.push("/dashboard/events");
  };

  const getEventButtonTitle = () => {
    if (eventData.participantScopeDesc === "Open event") {
      if (
        eventData.loginUserStatus === "Not a visitor" ||
        eventData.loginUserStatus === "Declined"
      ) {
        return <FormattedMessage id={"Event_attend"} />;
      }
      if (eventData.loginUserStatus === "Invited") {
        return <FormattedMessage id={"Event_invited"} />;
      } else {
        return intl.formatMessage({ id: "Event_attending" });
      }
    } else {
      if (
        eventData.loginUserStatus === "Not a visitor" ||
        eventData.loginUserStatus === "Declined"
      ) {
        return <FormattedMessage id={"Event_joinas_guest"} />;
      }
      if (eventData.loginUserStatus === "Invited") {
        return <FormattedMessage id={"Event_invited"} />;
      } else if (eventData.loginUserStatus === "Applied") {
        return <FormattedMessage id={"Event_applied"} />;
      } else {
        return intl.formatMessage({ id: "Event_attending" });
      }
    }
  };
  const joinEventAction = () => {
    ClearCache("allEvents");
    // console.log(" joinEventAction userId ", userId)
    if (typeof userId !== "undefined" && userId !== null) {
      eventData.participantScopeDesc === "Open event"
        ? history.push("/attendEvent", {
          eventDetails: eventData,
        })
        : history.push("/joinEvent", {
          eventDetails: eventData,
        });
    } else {
      const params = QueryString.parse(window.location.search);
      if (params.inviteId) {
        history.push("/registration?inviteId=" + params.inviteId)
      } else {
        history.push("/registration")
      }
    }
  }

  const handleClickPopover = (event) => {
    setAnchorElPopover(openPopover ? null : event.currentTarget);
  };

  // const handleClosePopOver = () => {
  //   setAnchorElPopover(null);
  // };
  const popoverEnter = (event) => {
    // console.log("popoverEnter")
    setAnchorElPopover(event.currentTarget)
  };

  const popoverLeave = ({ currentTarget }) => {
    // console.log("popoverLeave")
    setAnchorElPopover(null)
  };
  return (
    <ThemeProvider theme={Theme_WIP}>
      {userId != null && <Navigationbar/>}
        {loadingEventInfo ? (
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              flex: "row",
              justifyContent: "center",
              width: "100%",
              height: 300,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Stack>
          </Box>
        ) : (
          <PageWithBanner
          header={<>
            <Grid container pt={8} gap={ThemeConstants.custom.spacings.md}>
              {data?.leaveFromEvent && <Grid item sm={12} xs={12}>
                {typeof data !== "undefined" &&
                  data.leaveFromEvent.errorCode === 0 ? (
                  <SuccessAlertDialog
                    message={
                      <FormattedMessage
                        id={"YouLeftTheEventSuccessfully"}
                      />
                    }
                    open={true}
                  />
                ) : null}
                {typeof data !== "undefined" &&
                  data.leaveFromEvent.errorCode === -1 &&
                  isErrorAlertOpen && (
                  <Alert
                    severity={"error"}
                    onClose={() => {
                      setIsErrorAlertOpen(false);
                    }}
                  >
                    {<FormattedMessage id={"NowYouCantLeaveTheEvent"} />}
                  </Alert>
                )}
              </Grid>}
              <Background item sm={12} border="bottomLeft" color="transparent">
                <CardMedia
                  component="img"
                  image={
                    eventData.logo ?? "/assets/img/mastermatch_event_default.png"
                  }
                  alt={"mastermatch_logo"}
                  style={{
                    width: "100%",
                    maxHeight: "388px",
                    aspectRatio: "690 / 388",
                    borderRadius: "inherit"
                  }}
                />
              </Background>
              
              <Grid item container xs gap={ThemeConstants.custom.spacings.xs} sx={{minWidth: "300px"}}>
                {circleName && <Label>
                  {circleName}
                </Label>}
                <Text item xs={12} variant="h1" color="background" bold>
                  {usingUUID ? <FormattedMessage id="UuidInvite.Title" values={{name: eventData.title}} /> : eventData.title}
                </Text>
              </Grid>

              {eventData.quote && <Text item xs={12} variant="h2" color="background">
                {usingUUID ? <FormattedMessage id="UuidInvite.Subtitle" /> : eventData.quote}
              </Text>}
              {/* attendees count */}
              {/* {!usingUUID && <Stack direction="row" spacing={2} style={{ alignItems: "center" }}>
                <Text variant="body2" color="background" >
                  {(eventData.numberOfVisitors
                    ? eventData.numberOfVisitors
                    : "0") +
                    " " +
                    intl.formatMessage({ id: "Event_visitors" })}
                </Text>
                <IconButton
                  edge="start"
                  color={"default"}
                  aria-label="open drawer"
                  onClick={handleClickPopover}
                  onMouseEnter={popoverEnter}
                  onMouseLeave={popoverLeave}
                >
                  <InfoIcon sx={{ color: "black", width: 20 }} />

                </IconButton >
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={openPopover}
                  anchorEl={anchorElPopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={popoverLeave}
                  disableRestoreFocus
                >
                  <Text variant="body1" color="white">
                    {intl.formatMessage({ id: "Preference_listing_Nodata_Message" })}
                  </Text>
                </Popover>
              </Stack>} */}
              
              {allowedToAttend() && <React.Fragment>
                  {getEventButtonTitle() ===
                    intl.formatMessage({ id: "Event_attending" }) ? (
                    null // <Button
                    //   className={classes.attendingButton}
                    //   variant="outlined"
                    //   onClick={() => {
                    //     if (
                    //       eventData.loginUserStatus === "Not a visitor" ||
                    //       eventData.loginUserStatus === "Declined"
                    //     ) {
                    //       joinEventAction()
                    //     }
                    //   }}
                    // >
                    //   {getEventButtonTitle()}
                    // </Button>
                  ) : (
                    <Button variant="contained" sx={{height: "fit-content"}}
                      onClick={() => {
                        if (
                          eventData.loginUserStatus === "Not a visitor" ||
                          eventData.loginUserStatus === "Declined"
                        ) {
                          joinEventAction()
                        }
                      }}
                    >
                      {getEventButtonTitle()}
                    </Button>
                  )}
                {(eventData.loginUserStatus === "Manager" ||
                eventData.loginUserStatus === "Attending") && (
                  <Grid item xs>
                    <Button
                      aria-controls="demo-positioned-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className={classes.right}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <More/>
                      )}
                    </Button>
                    <Menu
                      style={{zIndex: 5000}}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem onClick={notAttendAction}>
                        <Text variant="body1" color="white" id={"Event_donot_attend"} />
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}
              </React.Fragment>}
            </Grid>
          </>}

          tabs={!usingUUID && [
          {label: <Text variant="body1" color="inherit" id={"circleDetails_Info"} />, value: 0},
          {label: <Text variant="body1" color="inherit" id={"circleDetails_News"} />,
           value: (eventData.hasNewsUpdate && userId && (eventData.loginUserStatus === "Manager" ||
            eventData.loginUserStatus === "Attending" || userRole === userRoles.admin)) && (
              1
          )},
          {label: <Text variant="body1" color="inherit" id={"EventDetails_Visitors"} />,
           value: (userRole === userRoles.admin || eventData.loginUserStatus === "Manager" || eventData.loginUserStatus === "Attending" ||
            (eventData.participantScopeDesc === "Gast event" &&
              eventData.hideVisitorsToPublic === null) ||
            (eventData.participantScopeDesc === "Gast event" &&
              eventData.hideVisitorsToPublic !== null &&
              !eventData.hideVisitorsToPublic) ||
            (eventData.participantScopeDesc !== "Gast event" && eventData.participantScopeDesc !== "Invite only event"
              && eventData.participantScopeDesc !== "Besloten event")) &&
            2
          },
          {label: <Text variant="body1" color="inherit" id={"EventDetails_Requests"} />,
           value: (userRole === userRoles.admin || eventData.loginUserStatus === "Manager" ||
            eventData.loginUserStatus === "Attending" ||
            (eventData.participantScopeDesc === "Gast event" &&
              eventData.hideVisitorsToPublic === null) ||
            (eventData.participantScopeDesc === "Gast event" &&
              eventData.hideVisitorsToPublic !== null &&
              !eventData.hideVisitorsToPublic) || eventData.participantScopeDesc !== "Gast event") &&
            3
          },
          {label: <Text variant="body1" color="inherit" id={"circleDetails_Invite"} />,
           value: 
           userRole === userRoles.admin 
           || (   eventData.isInvitationEnabled !== null 
               && eventData.isInvitationEnabled 
               && (   !eventData.isOrganizer 
                   || (eventData.isOrganizer && eventData.canEveryoneInvite) 
                   || (eventData.isOrganizer && eventData.loginUserStatus === "Manager")
            )) &&
            4
          },
          {label: <Text variant="body1" color="inherit" id={"circleDetails_Settings"} />,
           value: (eventData.loginUserStatus === "Manager" ||
            userRole === userRoles.admin) &&
              5
          }
          ]}

          tab={value}
          onChangeTab={handleChange}
          >

            <Grid container direction="row-reverse">
              {usingUUID && <Grid container item xs={12} mb={5} md={3} justifyContent="end" sx={{top: 60, position:"sticky"}}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={() => window.location.href = "https://mastermatch.net/hoofdpagina"}>
                    <Text p={0.5} variant="body1" color="inherit" id="UuidInvite.Button"/>
                  </Button>
                </Grid>
              </Grid>}
              
              <Grid container item xs={12} md={usingUUID ? 9 : 12}>
                <TabPanel value={value} index={0}>
                  {usingUUID && userId == null && <Background color="lighterPetrol" border="bottomLeft" mb={6} p={4} height="fit-content" py={6}>
                    <Text item xs={12} variant="h1"    id="UuidEventTitle"       color="background" mb={3} bold/>  
                    <Text item xs={12} variant="body1" id="UuidEventDescription" color="background"/>  
                  </Background>}
                  {usingUUID && userId == null && <SelfInviteForm source="events"/>}
                  <EventInfo eventDetails={eventData} uuid={usingUUID} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <EventNews
                    eventDetails={eventData}
                    selectedNewsId={selectedNewsId}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <EventVisitors eventDetails={eventData} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <EventRequest eventDetails={eventData} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <EventInvite
                    eventData={eventData}
                    circleData={eventData.circleId && (circleData ?? circle)}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <EventSettings eventDetails={eventData} />
                </TabPanel>
              </Grid>
            </Grid>
          </PageWithBanner>
        )}
    </ThemeProvider>
  );
}
