import React from "react";
import { CardMedia, Grid, ThemeProvider } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
import Label from "../../visualComponents/Label";

export default function EventHeader({errors, image, title, parentTitle, uuid, bio, button, members}) {
  const intl = useIntl();

  return (
    <ThemeProvider theme={Theme_WIP}>
        <Grid container pt={8} gap={ThemeConstants.custom.spacings.md}>
            {errors}
            <Background item sm={12} border="bottomLeft" color="transparent">
            <CardMedia
                component="img"
                image={image ?? "/assets/img/mastermatch_event_default.png"}
                alt={"mastermatch_logo"}
                style={{
                width: "100%",
                maxHeight: "388px",
                aspectRatio: "690 / 388",
                borderRadius: "inherit"
                }}
            />
            </Background>
            
            <Grid item container xs gap={ThemeConstants.custom.spacings.xs} sx={{minWidth: "300px"}}>
            {parentTitle && <Label>
                {parentTitle}
            </Label>}
            <Text item xs={12} variant="h1" color="background" bold>
                {uuid ? <FormattedMessage id="UuidInvite.Title" values={{name: title}} /> : title}
            </Text>
            </Grid>

            {bio && <Text item xs={12} variant="h2" color="background">
            {uuid ? <FormattedMessage id="UuidInvite.Subtitle" /> : bio}
            </Text>}
            {/* attendees count */}
            {!uuid && members && 
            <Text variant="body2" color="background" >
                {members +
                " " +
                intl.formatMessage({ id: "Event_visitors" })}
            </Text>}
            
            {button}
        </Grid>
    </ThemeProvider>
  );
}
