
const isEmail = (value) => {
    let regEmail = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
    if (regEmail.test(value.trim()))
        return true;
    return false;
};
const isEmailOrId = (value, masters) => {
    return isEmail(value) || masters.some((m) => m.userId == value);
};

const isValidPassword = (value) => {
    // let strongPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // if (strongPassword.test(value)) {
    //     return true;
    // }
    // return false;
    return value != "" && value != null;
};

const isValidURL = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    if (regex.test(URL)) {
        return true;
    }
    // console.log("url", URL)
    // console.log("url", regex.test(URL))

    return false;
};


export { isEmail, isEmailOrId, isValidPassword, isValidURL }