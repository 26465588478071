import { drawerWidth } from './CommonStyle'

const DashboardStyle = (theme) => ({
    root: {
        display: 'flex',
        pointerEvents: "none !important"
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        height: 50,
        backgroundColor: "white",
        borderWidth: 1,
        borderBottomColor: "grey",
        borderStyle: "none",
        boxShadow: "0px 2px 4px grey"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: theme.palette.primary.main
    },
    drawerPaper: {
        width: drawerWidth,
        // backgroundColor: theme.palette.primary.main

    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: "transparent",
        pointerEvents: "none !important",
        maxWidth: "100vw",
        overflowX: "hidden"
    },
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
        "& > *": {
        },
        width: "100%",
        pointerEvents: "none !important"
    },
    logo: {
        width: 240,
    }
});
export default DashboardStyle;