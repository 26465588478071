import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { styled } from "@mui/system";
import { Alert, Skeleton, ThemeProvider } from "@mui/material";
import { isEmailOrId } from "../../components/General";
import { SAVE_EVENTS_INVITE } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { GetUserId } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import { Save_Circle_Invite } from "../../apiServices/mutations";
import { useUUID } from "../../apiServices/Queries/Circles/GetUUID";
import { useListMasters } from "../../apiServices/Queries/Masters/ListMasters";
import Text from "../../visualComponents/Text";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import InputSection from "../../components/Forms/InputSection";

const Input = styled("input")({
  display: "none",
});

export default function InvitePeople({source, details}) {
  const intl = useIntl();
  const isEvent = source == "event";
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = React.useState("");
  const [input, setInput] = React.useState("");
  const [email, setEmail] = React.useState([]);
  const [email_, setEmail_] = React.useState([]); //used to keep track of selected autosuggested users. The autoSuggestList changes based on input so it's not always persistent
  const [csvarr, setCsvArr] = React.useState([]);
  const sourceData = details ?? (isEvent && location.state?.eventDetails ? location.state.eventDetails : location.state?.circleDetails);
  const [inputError, setInputError] = React.useState(false);
  const [csvError, setCsvError] = React.useState(false);
  const [isFormValid, setisFormValid] = React.useState(false);
  const [isCsvValid, setisCsvValid] = React.useState(false);
  const [isCsvValidtypeError, setIsCsvValidtypeError] = React.useState(false);
  const userId = GetUserId();

  const variables = {
    userId: userId,
    pageNumber: 1,
    pageSize: 10,
    isMyMatches: false,
    searchInput: input,
    showLoggedInUser: false,
  };
  const {data: masters, loading: loadingMasters, updateVariables: updateVariables} = useListMasters(variables);

  const onChange = (source, newvalue) => {
    setEmail (newvalue);
  };

  const {data: uuid, loadingUUID, updateVariables: reloadUUID} = useUUID(isEvent, sourceData?.id);
  useEffect(() => reloadUUID(isEvent? {
      eventId: sourceData?.id,
  } : {
      circleId: sourceData?.id,
  }), [sourceData]);
  
  const [saveInvite, { data, loading }] = useMutation(isEvent? SAVE_EVENTS_INVITE : Save_Circle_Invite);
  const updateTextFileds = (event) => {
    updateVariables({
      ...variables,
      searchInput: event.target.value
    });
    setInput(event.target.value);
  };

  const handleSave = () => {
    const isFormValid = email.length === 0 && csvarr.length === 0;
    setisFormValid(isFormValid);
    if (!isFormValid) {
      const emailFilter = email.map((option) =>
        !isEmailOrId(option, masters) && !email_.includes(option) ? "notvalidemail" : ""
      );
      let result = emailFilter.includes("notvalidemail");
      const isValidemail = result ? true : false;
      setInputError(isValidemail);
      const csvFilter = csvarr.map((option) =>
        !isEmailOrId(option, masters) ? "notvalidcsvemail" : ""
      );
      let csvresult = csvFilter.includes("notvalidcsvemail");
      const isValidcsv = csvresult ? true : false;
      setCsvError(csvresult);
      if (isValidemail === false && isValidcsv === false) {
        const invitedarr = email.length > 0 ? email.map(e => email_.includes(e) ? e.split(",")[0] : e ) : csvarr;
        const variables = isEvent? {
          eventId: sourceData.id,
          requestedUserId: userId,
          invitedMembers: invitedarr,
          message: message
        } : {
            circleId: sourceData.id,
            requestedUserId: userId,
            invitedMembers: invitedarr,
            message: message
        };
        
        saveInvite({
          variables: variables,
        });
      }
    }
  };
  const openFile = (_source) => {
    setInputError(false);
    setCsvError(false);
    setisFormValid(false);
    setIsCsvValidtypeError(false);
    var filename = _source.target.files[0].name;
    var filetype = _source.target.files[0].type; //"text/csv"
    if (
      filetype === "text/csv" ||
      filetype === "application/vnd.ms-excel" ||
      filetype === "application/csv"
    ) {
      var reader = new FileReader();
      reader.onload = function () {
        var filedata = reader.result;
        // console.log();
        if (filedata && filedata.trim().length > 0) {
          setisCsvValid(false);
          // console.log("filedata", filedata);
          var data = filedata.replace(/\n|\r/g, ","); //remove /n/r
          data = data.replace(/,+/g, ","); //remove doublecomma
          data = data.replace(/,\s*$/, ""); //lastcomma remove
          // console.log("result", data);
          var contentarr = data.split(",");
          setCsvArr(contentarr);
          // console.log("result", contentarr);
          document.getElementById("uploadedfile").innerHTML = filename;
        } else {
          setisCsvValid(true);
        }

        _source.target.value = "";
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(_source.target.files[0]);
    } else {
      setIsCsvValidtypeError(true);
    }
  };

  const handleSucessAlert = () => {
    if(isEvent){
        history.push("/dashboard/eventDetails/?id=" + sourceData.id, {
            eventDetails: sourceData,
            selectedTab: 4,
        });
    } else {
        history.push("/dashboard/circleDetails/?id=" + sourceData.id, {
            circleDetails: sourceData,
            selectedTab: 6,
        });
    }
  };
  const highlight = {hl: (...chunks) => <a href="https://mastermatch.freshdesk.com/support/solutions/articles/201000076247-hoe-upload-ik-een-csv-bestand-" target="_blank" style={{
    textDecoration: "underline",
    color: ThemeConstants.palette.background.contrastText
  }}>{chunks}</a>};

  return (
    <ThemeProvider theme={Theme_WIP}>
      <Grid container item xs={12}>
        <Grid item sm={12} xs={12}>
          {typeof data !== "undefined" &&
            (isEvent? data.saveEventInvite.errorCode : data.saveCircleInvite.errorCode) === 0 ? (
            <SuccessAlertWithAction
              open={true}
              message={<FormattedMessage id={"event_invite_success"} />}
              handleClose={handleSucessAlert}

            />
          ) : null}
          {typeof data !== "undefined" &&
            (isEvent? data.saveEventInvite.errorCode : data.saveCircleInvite.errorCode) === -1 ? (
            <Alert severity={"error"}>
              {(isEvent? data.saveEventInvite : data.saveCircleInvite).message}
            </Alert>
          ) : null}
        </Grid>
        <Grid container item xs={12} gap={ThemeConstants.custom.spacings.lg}>
          <InputSection
            type        = "users"
            options     = {masters}
            inputProps  = {{ color: "white" }}
            disabled    = {loading}
            id          = "emailIds"
            title       = {<FormattedMessage id="CircleInvite_AddEmailAddresses"/>}
            description = {<FormattedMessage id="CircleInvite_AddEmailAddresses_Description"/>}
            placeholder = {intl.formatMessage({id: "CircleInvite_Placeholder"})}
            onChange    = {onChange}
            hint        = {isFormValid ? <FormattedMessage id="invite_email_error" /> : undefined}
          />

          <Grid item xs={12} sm={12}>
            <InputSection
              title       = {<FormattedMessage id="CircleInvite_importcsv"/>}
              description = {<FormattedMessage id="InviteTabQR" values={highlight}/>}
            />
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <label htmlFor="contained-button-file">
                <Input
                  accept=".csv"
                  id="contained-button-file"
                  type="file"
                  onChange={openFile}
                />
                <Button
                  component="span"
                  variant="contained" color="white"
                  style={{ width: "20%", marginTop: "5px" }}
                >
                  <FormattedMessage id={"Upload"} />
                </Button>{" "}
                <span id="uploadedfile"></span>
              </label>
            )}
            {isCsvValidtypeError && <Text id="Invite_Valdation_Filevalidation"  variant="body2"/>}
            {csvError            && <Text id="Invite_Valdation_Emailvalidation" variant="body2"/>}
            {isCsvValid          && <Text id="Invite_Valdation_Atleast1Email"   variant="body2"/>}
          </Grid>

          <InputSection
            type        = "textarea"
            inputProps  = {{ color: "white" }}
            disabled    = {loading}
            id          = "emailIds"
            title       = {<FormattedMessage id="OnboardingInvite.message.label"/>}
            description = {<FormattedMessage id="OnboardingInvite.message.description"/>}
            placeholder = {intl.formatMessage({id: "Type"})}
            onChange    = {(value) => setMessage(value.target.value)}
          />
          <Grid item sm={3} xs={12}>
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <Button variant="contained" onClick={handleSave}>
                <FormattedMessage id={"Button_Text_SendInvite"} />
              </Button>
            )}
          </Grid>
        </Grid>
        
      </Grid>
    </ThemeProvider>
  );
}
