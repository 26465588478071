import * as React from "react";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../components/Navigationbar";
import Footer from "../components/Footer";
import { useHistory, useLocation } from "react-router";
import { RESPOND_TO_REQUEST } from "../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { ActivitySource } from "../apiServices/Constants";
import { GetUserId } from "../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import FormContainer from "../components/Forms/FormContainer";
import InputSection from "../components/Forms/InputSection";

export default function RespondToRequestPage() {
  const userId = GetUserId();
  const intl = useIntl();
  const history = useHistory();
  let [message, setMessage] = React.useState("");
  let [messageError, setMessageError] = React.useState("");
  const [request, setRequest] = React.useState({});
  const [news, setNews] = React.useState({});
  const location = useLocation();

  const [repondToRequest, { data, loading, error }] =
    useMutation(RESPOND_TO_REQUEST);

  const updateTextFileds = (event) => {
    const { value } = event.target;
    setMessage(value);
    setMessageError("");
  };

  const handleSave = async () => {
    let isValid = await checkForErrors();
    let variable = {};
    if (location.state && location.state.request) {
      variable = {
        activitySource: ActivitySource.collabRequest,
        collaborationType: request.requestType.value,
        activitySourceId: request.id,
        messages: message,
        recipientUserProfileIds: [request.userId],
        requesterUserProfileId: userId,
      };
    } else if (location.state && location.state.news) {
      variable = {
        activitySource: ActivitySource.circleNewsUpdate,
        activitySourceId: news.id,
        messages: message,
        recipientUserProfileIds: [news.createdProfileId],
        requesterUserProfileId: userId,
      };
    }
    // console.log("reuqest News : ", variable);
    if (isValid) {
      repondToRequest({
        variables: variable,
      });
    }
  };
  React.useEffect(() => {
    // console.log("props data", location.state.news);
    if (location.state && location.state.request) {
      // console.log("respond To request data", location.state.request);
      setRequest(location.state.request);
    } else if (typeof location.state.news !== "undefined") {
      setNews(location.state.news);
    }
  }, [location, data]);

  const checkForErrors = async () => {
    setMessageError(!message.trim() ? "messageError" : "");
    return message.trim();
  };

  const proceed = () => {
    if (location?.state?.referrer)
      history.push(location?.state?.referrer, location?.state?.oldState);
    else
      history.go(-2);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {data?.sendResponseToActivityActivity?.errorCode ==  0 ? <FormattedMessage id={"responseSentSuccessfully"} /> : undefined}
        onModalClose = {proceed}
        error        = {data?.sendResponseToActivityActivity?.errorCode == -1 ? data.sendResponseToActivityActivity.message : undefined}
        title        = {<FormattedMessage id="RespondToRequest"/>}
        loading      = {loading}
        onSave       = {handleSave}
        disabled     = {loading || !message}
        onCancel     = {proceed}
        saveId       = "Button_text_Send"
      >
        <InputSection
        type        = "textarea"
        disabled    = {loading}
        id          = "message"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id="writeAMessage"/>}
        onChange    = {updateTextFileds}
        hint        = {messageError?.length ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {1000}
        />
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
