import { useEffect, useState } from "react";
import { useAdminUserIdQuery } from "./CommonDataObjects";
import { useAuthContext } from "./UserProvider";
import { userRoles } from "./Constants";
import userManager from "../components/IdentityServer/userService";
import { useQuery } from "@apollo/client";
import { GET_USER_ROLE_BY_ID } from "./Queries";

//since in App.js routing is called in a child of UserProvider,
//the user session will already be validated for all components except the few ones accessed in higher levels of App.js
//since the router is redirecting users away from pages if theyre not authenticated,
//it is save for most components on authenticated pages to assume the useAuthContext().user will not be null or undefined
//These three methods should not be called on public pages since useAuthContext().user may be undefined
function AuthContext() {
  if(!IsAuthenticated()) {
    console.warn("No authenticated session found on a component requiring authenticated context.");
    // ClearItems();
    // userManager.clearStaleState()
    // userManager.removeUser()
    // window.location.reload();
  }
  return useAuthContext();
}

//TODO: there's over a hundred references to this where
// the following could be used instead:
// const globals = useGlobalContext();
// const userId  = globals.state.loggedInUser.userId;
export function GetUserId() {
  const user = AuthContext().user;
  if (user != undefined)
    return user.profile.sub;
  else return null;
}
export const GetAccessToken = () => AuthContext().user.profile.id_token;

export function useUserRole(userId = null, roleOnly = true) {
  const id              = userId ?? GetUserId();
  const {data: adminId} = useAdminUserIdQuery();
  const {loading, data, refetch} = useQuery(GET_USER_ROLE_BY_ID, {
    variables: {
      id: id
    },
    nextFetchPolicy: "network-only"
  });
  const role = loading || data == null || id == null || adminId == null ? 
    {role: userRoles.freemium} 
  : (adminId == id ? {...data.currentRoleByUserId, role: userRoles.admin} : data.currentRoleByUserId)

  return roleOnly ? role?.role : [role, refetch, loading];
}

export const IsAuthenticated = () => {
  const context = useAuthContext();
  return context.loadedUser 
      && context.user != null 
      && context.user.profile.sub != null
      && context.user.access_token !== ""
      && context.user.expires_at > parseInt(Date.now() / 1000);
};

export const ClearItems = () => {//inviteInfo
  const seenVideo       = localStorage.getItem("seenVideo");
  const inviteInfo      = localStorage.getItem("inviteInfo");
  const acceptedInvites = localStorage.getItem("acceptedInvites"); //onBoardProgress
  const onBoardProgress = localStorage.getItem("onBoardProgress");
  localStorage.clear();
  if(seenVideo      ) localStorage.setItem("seenVideo"      , seenVideo      ); //don't make users sit through the onboarding video again upon logout
  if(inviteInfo     ) localStorage.setItem("inviteInfo"     , inviteInfo     ); //don't delete the invite the user registered with
  if(acceptedInvites) localStorage.setItem("acceptedInvites", acceptedInvites); //don't display the premium trial modal again
  if(acceptedInvites) localStorage.setItem("onBoardProgress", onBoardProgress); //don't re-Onboard
}
export function ClearCache (id = "", globals) {
  for (var key in localStorage) {
    if(key.startsWith("cache_" + id))
      localStorage.removeItem(key);
  }
  if(globals)
    globals.state.cache.set(globals.state.cache.get.filter(c => !c.hash.startsWith("cache_" + id)));
}

export const isOnboardingProcessCompleted = () => {
  return localStorage.getItem("onBoardProgress") != null && localStorage.getItem("onBoardProgress") > 3;
};
export const isOnboardingInProgress = () => {
  return localStorage.getItem("onBoardProgress") != null && localStorage.getItem("onBoardProgress") <= 3;
};

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });

export const getDateString = (dateString) => {
  const date = new Date(dateString);
  return formatAMPM(date) + " " + date.yyyymmdd();
};
export const getDateWith24Hrs = (dateString) => {
  const date = new Date(dateString);
  return date.yyyymmdd() + " " + format24Time(date);
};
export const getDateOnlyString = (date) => {
  return date.yyyymmdd();
};
export const getValidUrl = (url) => {
  return url.startsWith("http") ? url : "https://" + url;
};
export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
}
export function format24Time(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes;
}

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    this.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("-");
};

Date.prototype.dateWithFormat = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
    this.getFullYear(),
  ].join("/");
};

Date.prototype.monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

Date.prototype.getMonthName = function () {
  return this.monthNames[this.getMonth()];
};
export function getLongDate(dateString) {
  const date = new Date(dateString);
  var mm = date.getMonthName(); // getMonth() is zero-based
  var dd = date.getDate();

  return (dd > 9 ? "" : "0") + dd + " " + mm;
}

export function getLongDateWithYear(dateString) {
  const date = new Date(dateString);
  var mm = date.getMonthName(); // getMonth() is zero-based
  var dd = date.getDate();

  return (dd > 9 ? "" : "0") + dd + " " + mm + " " + date.getFullYear();
}
export function getdayValue(dateString) {
  const date = new Date(dateString);
  var dd = date.getDate();
  return dd
}
export function getMonthValue(dateString) {
  const date = new Date(dateString);
  var mm = date.getMonthName();
  return mm
}
export function getYearValue(dateString) {
  const date = new Date(dateString);
  return date.getFullYear()
}
export function getDateWithShortMonth(dateString) {
  const date = new Date(dateString);
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return (dd > 9 ? "" : "0") + dd + "/" + mm + "/" + date.getFullYear();
}
export function formatTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + ":" + "00" + " " + ampm;
}

export function combineDateAndTime(dateValue, timeValue) {
  // console.log("dateValue", dateValue);
  // console.log("timeValue", timeValue);
  const formattedDateString =
    dateValue.dateWithFormat() + " " + timeValue;
  // console.log("formattedDateString", formattedDateString);
  return new Date(formattedDateString);
}

export function getDateFormatNews(dateString) {
  const date = new Date(dateString);
  var mm = date.toLocaleString("en-us", { month: "short" }); /* Jun */
  var dd = date.getDate();
  return (dd > 9 ? "" : "0") + dd + " " + mm;
}

export function get24Time(dateString) {
  const date = new Date(dateString);
  var hours = date.getHours();
  var minutes = date.getMinutes() === 0 ? "00" : date.getMinutes();
  return hours + ":" + minutes;
}


export function add30Minutes(date) {
  date.setTime(date.getTime() + (1 * 60 * 1000));
  return date
}

export function isInThePast(date) {
  const today = new Date();

  // 👇️ OPTIONAL!
  // This line sets the hour of the current date to midnight
  // so the comparison only returns `true` if the passed in date
  // is at least yesterday
  // today.setHours(0, 0, 0, 0);

  return date < today;
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export const getValueFromJsonKeyValue = (keyValueList, key) => keyValueList != null && keyValueList.length > 0 ? keyValueList.filter((obj) => obj?.key == key)[0]?.value : null;


export function YYYYMMDDTHHMM (date) {
  const time = format24Time(date);
  return date.yyyymmdd() + "T" + (time.length < 5 ? "0" + time : time);
}
export function startYYYYMMDDTHHMM (date) {
  const time = format24Time(date);
  return date.yyyymmdd() + "T00:00";
}
export function endYYYYMMDDTHHMM (date) {
  const time = format24Time(date);
  return date.yyyymmdd() + "T23:59";
}