import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import CircleInfo from "../../components/Circles/CircleInfo";
import CircleNews from "../../components/Circles/CircleNews";
import CircleMembers from "../../components/Circles/CircleMembers";
import CircleFeed from "../../components/Circles/CircleFeed";
import CircleEvents from "../../components/Circles/CircleEvents";
import CircleCirles from "../../components/Circles/CircleCirles";
import CircleInvite from "../../components/Circles/CircleInvite";
import CircleSettings from "../../components/Circles/CircleSettings";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CIRCLE_BY_UUID, GET_CIRCLE_INFO } from "../../apiServices/Queries";
import { useDispatch } from "react-redux";
import {
  updateBrandColor,
} from "../../apiServices/Redux/Reducer";
import { LEAVE_FROM_CIRCLE } from "../../apiServices/mutations";
import SuccessAlertDialog from "../../components/Alert/SuccessAlertDialog";
import { Alert, Stack, ThemeProvider } from "@mui/material";
import QueryString from "query-string";
import { FormattedMessage } from "react-intl";
import {
  userRoles,
} from "../../apiServices/Constants";
import SelfInviteForm from "../../components/Invites/SelfInviteForm";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import Navigationbar from "../../components/Navigationbar";
import { ClearCache } from "../../apiServices/CommonMethods";
import PageWithBanner from "../../components/Layout/PageWithBanner";
import CircleHeader from "../../components/Circles/CircleHeader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    value === index && <Grid container
      style={{pointerEvents: "none !important"}}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function CircleDetails(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [circleData, setCircleData] = React.useState({});
  const [selectedCircleId, setSelectedCircleId] = React.useState(null);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(false);
  const [selectedNewsId, setSelectedNewsId] = React.useState("");
  const [selectedPostID, setSelectedPostID] = React.useState("");
  const [usingUUID, setUsingUUID] = React.useState(false);

  const [leaveCircle, { data, loading }] = useMutation(LEAVE_FROM_CIRCLE, {
    onCompleted: (leaveData) => {
      ClearCache("circle");
      ClearCache("allCircles");
      // console.log("dataCircle", leaveData)
      if (typeof leaveData !== "undefined" && typeof leaveData.leaveFromCircle !== "undefined" && leaveData.leaveFromCircle.errorCode === 0) {
        dispatch(updateBrandColor(theme.palette.primary.main));
        history.push("/dashboard/circles");
      } else if (typeof leaveData !== "undefined" && typeof leaveData.leaveFromCircle !== "undefined" && leaveData.leaveFromCircle.errorCode === -1
      ) {
        setIsErrorAlertOpen(true);
      }
    },
  });
  const [fetchCircleInfo, { loading: loadingCircleInfo },] = useLazyQuery(usingUUID? GET_CIRCLE_BY_UUID : GET_CIRCLE_INFO, {
    fetchPolicy: "network-only",
    variables: usingUUID? { uuid: selectedCircleId } : { circleId: selectedCircleId, loggedInUser: userId },
    onCompleted: (dataCircleInfo) => {
      if(usingUUID)
        setCircleData(dataCircleInfo?.circleByUUID?.result);
      else
        setCircleData(dataCircleInfo?.circleInfo?.result);
      dispatch(
        updateBrandColor(
          dataCircleInfo.brandColor ??
          theme.palette.primary.main
        )
      );
      if (location.state && typeof location.state.selectedTab !== "undefined") {
        // console.log("selected Tab", location.state.selectedTab)
        setValue(location.state.selectedTab);
      } else if (dataCircleInfo.loggedInUserRole === "Moderator" ||
        dataCircleInfo.loggedInUserRole === "SuperUser" ||
        userRole === userRoles.admin) {
        setValue(7);
      }
    },
  });

  useEffect(() => {
    const params = QueryString.parse(window.location.search);
    const newId  = params.id ?? location.state?.selectedCircleId ?? params.uuid;
    
    if(newId && newId != selectedCircleId)
      setSelectedCircleId(newId);

    //get the circle details from query - uuid from the query parameter
    if (params.uuid) {
      setUsingUUID(true);
    }

    // get circle details from saved state
    if (location.state?.circleDetails) {
      // console.log("circleDetails", location.state.circleDetails);
      setCircleData(location.state.circleDetails);
      dispatch(
        updateBrandColor(
          location.state.circleDetails.brandColor ?? theme.palette.primary.main
        )
      );
    }

    if (location.state?.selectedTab)
      setValue(location.state.selectedTab);

    if (location.state?.selectedNewsID)
      setSelectedNewsId(location.state.selectedNewsID);

    if (location.state?.selectedPostID)
      setSelectedPostID(location.state.selectedPostID);
      
    if (!params.uuid && !params.id && (typeof location.state === "undefined" || (location.state && typeof location.state.circleDetails === "undefined" && typeof location.state.selectedCircleId === "undefined"))) {
      dispatch(updateBrandColor(theme.palette.primary.main));
      history.push("/dashboard/circles");
    }
  }, [location]);

  useEffect(() => {
    fetchCircleInfo();
  }, [selectedCircleId]);

  const handleChange = (newValue) => {
    setValue(newValue);
    setSelectedPostID(null)
    setSelectedNewsId(null)
  };

  const refresh = (circle, selectedTab) => {
    // setSelectedCircleId(circle.id);
    // fetchCircleInfo();
    setCircleData(circle);
    if (typeof selectedTab !== "undefined") {
      setValue(selectedTab);
    }
  };

  const leaveCurrentCircle = () => {
    const variable = {
      circleId: circleData.id,
      loginProfileId: userId,
      profileId: userId,
    };
    leaveCircle({
      variables: variable,
    });
  };

  const UpdateCircleDetails = (data) => {
    // console.log("datas", data);
    setSelectedCircleId(data.id);
    setCircleData(data);
    setValue(0);
  };

  const contactButton = circleData.loggedInUserRole !== "Moderator" &&
  circleData.loggedInUserRole !== "SuperUser" &&
  userId &&
  <Button
    variant="contained"
    color="primary"
    onClick={() => {
      history.push("/contactCircleManager", {
        circleData: circleData,
      });
    }}
    disableElevation
  >
    <FormattedMessage id={"circleDetails_Contact"} />
  </Button>;


  return (
    <ThemeProvider theme={Theme_WIP}>
      {/* {!loadingCircleInfo && circleData.brandColor && 
        <Background page color={circleData.brandColor}/>
      } */}
      {userId != null && <Navigationbar />}
      {loadingCircleInfo ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Stack>
        </Box>
      ) : (
        <PageWithBanner 
        tab={value} 
        onChangeTab={handleChange}
        header={
            <CircleHeader
              errors={data?.leaveFromCircle?.errorCode != undefined &&
                <Grid item sm={12} xs={12}>
                  {data.leaveFromCircle.errorCode === 0 && <SuccessAlertDialog
                    message={
                      <FormattedMessage
                        id={"YouLeftTheCircleSuccessfully"}
                      />
                    }
                    open={true}
                  />}
                  {data.leaveFromCircle.errorCode === -1 &&
                    isErrorAlertOpen &&
                    <Alert
                      severity={"error"}
                      onClose={() => {
                        setIsErrorAlertOpen(false);
                      }}
                    >
                      {<FormattedMessage id={"NowYouCantLeaveTheCircle"} />}
                  </Alert>}
                </Grid>}
              image       = {circleData.logo}
              button      = {contactButton}
              parentTitle = {circleData?.parentCircleName}
              title       = {circleData.name}
              uuid        = {usingUUID}
              bio         = {circleData.shortDescription}
              loading     = {loading}
              menuItems   = {[<MenuItem onClick={leaveCurrentCircle}>
                <Text variant="body1" color="background" id="circleDetails_Leavecircle"/>
              </MenuItem>]}
            />}

        tabs={!usingUUID && [
          {label: <Text variant="body1" color="inherit" id={"circleDetails_Info"} />,
           value: 0
          },
          {label: <Text variant="body1" color="inherit" id={"circleDetails_News"} />,
           value: ((circleData.loggedInUserRole === "Moderator" ||
            circleData.loggedInUserRole === "Member" ||
            circleData.loggedInUserRole === "SuperUser" || userRole === userRoles.admin) &&
            circleData.hasNewsUpdates === true &&
            circleData.circleTypeId === "2") && 
            1
          },
          {label: <Text variant="body1" color="inherit" id={"circleDetails_Members"} />,
           value: (!(
            typeof circleData.membersArePubliclyVisible ===
            "boolean" && circleData.membersArePubliclyVisible
          ) || circleData.loggedInUserRole !== null) &&
            2
          },
          {label: <Text variant="body1" color="inherit" id={"circleDetails_Feed"} />,
           value: (circleData.loggedInUserRole === "Moderator" ||
            circleData.loggedInUserRole === "Member" ||
            circleData.loggedInUserRole === "SuperUser" ||
            userRole === userRoles.admin) &&
            3
          },
          {label: <Text variant="body1" color="inherit" id="circleDetails_Events" />,
           value: 4
          },
          {label: <Text variant="body1" color="inherit" id="circleDetails_Circles" />,
           value: (circleData.canHaveSubCircles &&
            circleData.circleTypeId === "2" && (circleData.loggedInUserRole === "Moderator" ||
              circleData.loggedInUserRole === "Member" ||
              circleData.loggedInUserRole === "SuperUser")) &&
            5
          },
          {label: <Text variant="body1" color="inherit" id="circleDetails_Invite"/>,
           value: ( circleData.loggedInUserRole === "Moderator" ||
                    circleData.loggedInUserRole === "SuperUser") && 
           6
          },
          {label: <Text variant="body1" color="inherit" id="circleDetails_Settings"/>,
           value: (circleData.loggedInUserRole === "Moderator" ||
            circleData.loggedInUserRole === "SuperUser" ||
            userRole === userRoles.admin) &&
            7
          }
        ]}
        >
          <Grid container direction="row-reverse" pb={20}>

            {usingUUID && <Grid container item xs={12} mb={5} md={3} justifyContent="end" sx={{top: 60, position:"sticky"}}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => window.location.href = "https://mastermatch.net/hoofdpagina"}>
                  <Text p={0.5} variant="body1" color="inherit" id="UuidInvite.Button"/>
                </Button>
              </Grid>
            </Grid>}

            <Grid container item xs={12} md={usingUUID ? 9 : 12} sx={{ width: "100%", height: "fit-content !important"}}>
              <TabPanel value={value} index={0}>
                {usingUUID && userId == null && <Background color="lighterPetrol" border="bottomLeft" mb={6} p={4} height="fit-content !important" py={6}>
                  <Text item xs={12} variant="h1"    id="UuidCircleTitle"       color="background" mb={3} bold/>  
                  <Text item xs={12} variant="body1" id="UuidCircleDescription" color="background"/>  
                </Background>}
                {usingUUID && userId == null && <SelfInviteForm source="circles"/>}
                <CircleInfo circleDetails={circleData} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CircleNews circleDetails={circleData} selectedNewsId={selectedNewsId} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CircleMembers circleDetails={circleData} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <CircleFeed circleDetails={circleData} selectedPostID={selectedPostID} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <CircleEvents circleDetails={circleData} />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <CircleCirles
                  circleDetails={circleData}
                  UpdateCircleDetails={UpdateCircleDetails}
                />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <CircleInvite circleDetails={circleData} />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <CircleSettings
                  circleDetails={circleData}
                  onRefresh={refresh}
                />
              </TabPanel>
            </Grid>
            
          </Grid>
        </PageWithBanner>
      )}
    </ThemeProvider>
  );
}
